/*
.companion{
  &-articles{
    &__title{
      font-family: $rb;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #000;
    }
    &__top{
      margin-top: 80px;
      margin-bottom: 48px;
    }
    &__more{
      text-decoration: none;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .swc-slider-arrows{
      justify-content: flex-end;
      float: right;
    }
    &__count{
      float: right;
      font-family: $rm;
      font-size: 20px;
      color: #003087;
      bottom: 0;
      margin-right: 50px;
      line-height: 50px;
      &-bottom{
        display: none;
      }
    }
  }
}
@media screen and (min-width: $tab-max) {
  .companion-articles{
    display: none;
  }
}
@media screen and (max-width: $tab-min){
  .companion-articles{
    &__top{
      margin-bottom: 30px;
    }
    &__count{
      &-top{
       display: none;
      }
      &-bottom{
        display: block;
        float: none;
        text-align: center;
        margin-right: 0;
      }
    }
    .news-list__new-wrap_blog{
      margin-bottom: 10px;
    }
    .news-list{
      &__new-body{
        padding-top: 29px;
      }
    }
  }
}*/
