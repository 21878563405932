.video-banner {
  //@include outr;
  //position: fixed;
  height: 100vh;
  //width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  position: relative;
  overflow: hidden;

  &-wrap {
    //height: calc(100vh + 300px);
    //padding: 0;
  }

  &__info {
    height: 100vh;
    border-top: 70px solid #f4f4f4;
    border-right: 80px solid #f4f4f4;
    border-left: 80px solid #f4f4f4;
    border-bottom: 70px solid #f4f4f4;
    position: relative;
    white-space: nowrap;
    padding: 30px;
    background-color: rgba(0, 48, 135, .3);
    z-index: 2;
    pointer-events: none;

    &:after {
      display: inline-block;
      vertical-align: middle;
      content: '';
      height: 100%;
      width: 0;
    }

    &-title {
      white-space: normal;
      //max-width: 840px;
      margin: auto;
      color: white;
      display: inline-block;
      vertical-align: middle;
      font: 64px/1.33 $inter;
      font-size: 5vw;

      span {
        font: 64px/1.33 $inter;
        font-size: 5vw;
      }
    }
  }

  &_technology {
    //background-image: url('../img/bg/1.jpg');
    //background-size: cover;
    //background-position: center;
  }

  @-webkit-keyframes animate-banner-title {
    0% {
      opacity: 0;
      transform: translateY(50px)
    }

    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  video {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
  }

  &-title {
    background-color: #f4f4f4;
    padding-bottom: 48px;
    & .swc-subtitle{
      margin-bottom: 0px;
    }
  }

  &_years {
    //@include outw;
    @include flex(row, space-around, center);
    flex-grow: 3;
  }

  &_tabs{
    background-color: #f4f4f4;
    max-width: 1060px;
    margin: auto;
    padding: 0 70px;
  }
  &_tabs {
    &-title {
      //@include outw;
      font-family: $rb;
      font-size: 56px;
      line-height: 1.29;
      color: #fff;
      flex-grow: 1;
      border-bottom: 8px solid transparent;
    }
    &-tab {
      font: 20px/1.35 $inter;
      color: #000;
      cursor: pointer;
      font-weight: 900;
      &.active {
        text-decoration: underline;
      }
    }
  }

  &_img {
    position: relative;
    background-size: cover;
    background-position: center;

    &.clean {
      background-image: none;
    }
  }
  &_play {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 50%;
    margin: -35px 0 0 -35px;
    cursor: pointer;
    z-index: 3;
    opacity: 1;
    transition: opacity 0.3s;

    &.no_active {
      opacity: 0!important;
      transition: opacity 0.3s;
    }
  }
}

#m-video-banner-info-title {
  font: 900 64px/1.33 $inter;
}

@media screen and (max-width: $tab-max) {

  .video-banner {

    &__info {
      border-top: 64px solid #f4f4f4;
      border-right: 0px solid #f4f4f4;
      border-left: 0px solid #f4f4f4;
      border-bottom: 64px solid #f4f4f4;
    }

  }

}

@media screen and (max-width: $tab-min) {
  #m-video-banner-info-title {
    font: 900 28px/1.33 $inter;
  }

  .video-banner {
    &_tabs{
      padding: 0 24px;
    }
    &__title{
      padding-bottom: 68px;
      & .swc-subtitle{
        margin-bottom: 0px;
      }
    }
  }

}
























