.reviews {
  padding-top: 120px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  .slick-list.draggable {
    height: 100% !important;
    height: 100% !important;
  }

  .swc-title {
    width: 580px;
    text-align: left;
    margin: unset;
    margin-bottom: 90px;
    letter-spacing: unset;
  }

  &_grey {
    background-color: #fafbfc;
  }

  &__slider {
    margin: 0 -20px;
    font-size: 0;
    padding-bottom: 70px;
    text-align: center;
  }

  &__numbers {
    position: absolute;
    left: 330px;
    font: 400 24px/1.5 $inter;
  }

  &__slide {
    display: flex !important;
    padding: 0 20px;
    vertical-align: top;
    text-align: left;

    &_full {
      padding: 80px 110px;
      vertical-align: middle;
      width: auto;
      max-width: 840px;

      .reviews__slide-top {
        margin-bottom: 50px;
      }

      .reviews__slide-text {
        font: 18px/2 $rr;
        max-height: none;

        p {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-top {
      display: flex;
      width: 290px;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &-img {
      padding-bottom: 8px;
      flex-shrink: 0;
      width: 144px;

      img {
        border-radius: 50%;
      }
    }

    &-info {
      flex-grow: 2;
    }

    &-flag {
      width: 30px;
      height: 20px;
      vertical-align: bottom;
      margin-right: 8px;
    }

    &-name {
      font: 800 24px/1.5 $inter;
      margin-bottom: 8px;
    }

    &-age {
      font: 400 16px/1.5 $inter;
      color: #4a4a4a;
      margin-bottom: 26px;
    }

    &-country {
      display: flex;
      align-items: center;
      font: 400 16px/1.5 $inter;
      text-transform: uppercase;
      color: #4a4a4a;
    }

    &-text {
      display: block;
      width: 730px;
      margin-left: 40px;
      padding: 72px 110px;
      font: 400 24px/1.5 $inter;
      background-color: #fff;

      .text {
        overflow: hidden;

        &.full {
          height: auto !important;
        }
      }
    }

    &-more {
      display: block;
      position: relative;
      margin-top: 24px;
      cursor: pointer;

      &.hide {
        display: none;
      }

      &.visible {
        display: block;
      }

      span {
        display: inline-block;
        position: relative;
        border-bottom: 1px dashed #000;
        font: 800 24px/1.5 $inter;
        color: #000;
      }
      
      &:hover {
        text-decoration: none;
      }
    }

    &-more-close {
      display: block;
      position: relative;
      margin-top: 24px;
      cursor: pointer;

      &.hide {
        display: none;
      }

      &.visible {
        display: block;
      }

      span {
        display: inline-block;
        position: relative;
        border-bottom: 1px dashed #000;
        font: 800 24px/1.5 $inter;
        color: #000;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__nav {
    position: relative;

    @include flex(row, flex-end, center);
    height: 50px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: $tab-min) {

  .reviews {

    .swc-title {
      width: 100%;
    }

    &__numbers {
      left: 0;
    }

    &__slide {
      flex-direction: column-reverse;

      &-top {
        flex-direction: row;
        margin-top: 24px;
        margin-bottom: 0;
        width: 100%;
      }

      &-text {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .reviews {
    &__slide {

      &-top {
        flex-direction: column;
      }

      &-text {
        padding: 40px 72px;
        font-size: 16px;
      }

      &-more {
        span {
          font-size: 16px;
        }
      }

      &-more-close {
        span {
          font-size: 16px;
        }
      }
    }
  }
}