html, body {
	height: 100%;
}

body {
	background-color: #f4f4f4;
	display: flex;
	flex-direction: column;
}

.container {
	max-width: 1200px;
	min-width: 320px;
	margin: 0 auto;
	//padding: 0 70px;

	&_preview {
		padding-right: 0;
	}

	&_wide {
		//max-width: 1440px;
		//padding: 0;
	}
	&_news {

	}
}

@media screen and (max-width: $tab-max) {
	.container {
		padding: 0 70px;
		&_news {
			padding: 0 24px;
		}
	}
}

@media screen and (max-width: $tab-min) {
	.container {

		&_news {
			margin: 0;
			width: 100%;
			padding: 36px 16px 0 !important;

		}
	}
}
@media screen and (max-width: 480px) {
	.container {
		padding: 0 24px;
	}
}