.benefit {
  padding-bottom: 160px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 80px;
    font: 800 40px/1.5 $inter;
  }

  &__item {
    width: 730px;

    @include flex(row, flex-start, center);

    &:not(:last-child) {
      margin-bottom: 72px;
    }

    &-title {
      margin-bottom: 16px;
      font: 800 24px/1.5 $inter;
    }

    &-text {
      font: 400 16px/1.5 $inter;
    }

    &-image {
      margin-left: 40px;
    }
  }
}

@media screen and (max-width: $tab-min) {
  .benefit {
    &__item {
      width: 100%;
    }
  }
}

@media screen and (max-width: $mob-max) {

  .benefit {
    padding-bottom: 120px;

    &__title {
      font-size: 32px;
    }

    &__item {
      width: 100%;

      @include flex(column-reverse, flex-start, flex-start);

      &-image {
        margin-left: 0;
        margin-bottom: 40px;
      }
    }
  }
}


































