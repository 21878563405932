.disclaimer {
  position: fixed;
  padding-bottom: 200px;
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: #f4f4f4;
  z-index: 99;

  .container {
    position: relative;
    max-width: 1320px;
    padding: 0;
    z-index: 1;
  }

  .header__nav-langs-wrap {
    .container {
      display: block;
    }
  }

  .header__top {
    margin-bottom: 140px;
    background-color: transparent;
  }

  &__block {
    padding: 0 110px;
  }

  &__title {
    font: 900 64px/1.5 $inter;
    margin-bottom: 20px;
  }

  &__text {
    font: 400 24px/1.5 $inter;
    margin-bottom: 20px;
  }

  &__link-path {
    max-width: 756px;
    margin-bottom: 80px;
    padding: 24px;
    background-color: #fff;
    font: 400 18px/1.5 $inter;
  }

  &__content-title {
    margin-bottom: 40px;
    font: 800 24px/1.5 $inter;
  }

  &__content-text {
    max-width: 756px;
    max-height: 310px;
    margin-bottom: 40px;
    padding: 32px;
    font: 400 16px/1.5 $inter;
    border: 1px solid #000;
    overflow-y: auto;
  }

  .checkbox-container {
    display: block;
    position: relative;
    margin-bottom: 24px;

    div {
      padding-left: 40px;
      font: 400 16px/1.5 $inter;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 24px;
        height: 24px;
        border: 1px solid #000;
        background-color: #fff;
        transform: translatey(-50%);
      }
    }

    .check {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 8px;
        width: 16px;
        height: 3px;
        background-color: $btn;
        opacity: 0;
        transform: translatey(-50%) rotate(-45deg);
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 9px;
        left: 3px;
        width: 9px;
        height: 3px;
        background-color: $btn;
        opacity: 0;
        transform: rotate(45deg);
      }
    }
    
    input {
      &:checked {
        +div {
          &:before {
            background-color: #000;
          }
          .check {
            &:before,
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .disclaimer__btn {
    padding: 14px 108px;

    &.disclaimer__btn--disabled {
      background-color: #c3c3c3;
      border-color: #c3c3c3;
      color: #fff;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: $tab-min) {
  .disclaimer {

    .container {
      max-width: 628px;
    }

    &__block {
      padding: 0;
    }

    &__btn {
      padding: 14px 108px;
    }

    .header__top {
      margin-bottom: 160px;

      .container {
        justify-content: space-between;
      }
    }

    .header__call {
      display: block;
    }
    
    .header__logo {
      position: relative;
      left: unset;
    }

    .header__phone {
      font: 400 18px/1.5 $inter;
    }
  }
}

@media screen and (max-width: $mob-max) {
  .disclaimer {
    &__title {
      font-size: 44px;
    }

    &__link-path {
      line-break: anywhere;
    }

    &__btn {
      width: 100%;
    }

    .container {
      max-width: 327px;
    }

    .header__top {
      margin-bottom: 120px;

      .container {
        flex-wrap: wrap;
      }
    }

    .header__call {
      width: 100%;
      margin-bottom: 32px;
    }

    .header__logo {
      margin: 0;
    }
  }
}

.visually-hidden {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  border: 0;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
