.investors {
  padding-bottom: 160px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    background-color: #fff;
    max-width: 1320px;
    z-index: 1;
  }

  .swc-title {
    margin: 0;
    margin-bottom: 80px;
    padding-top: 120px;
    padding-left: 110px;
    text-align: left;
    font: 800 40px/1.5 $inter;
    letter-spacing: unset;
  }

  //&__bg {
  //  height: 150%;
  //  width: 100%;
  //  position: absolute;
  //  top: -50%;
  //  left: 0;
  //  right: 0;
  //  margin: auto;
  //  background: $main-blue url(../img/bg/flags.jpg) center no-repeat;
  //  background-size: cover;
  //  z-index: -1;
  //}

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 110px;
    padding-bottom: 100px;
    font-size: 0;
  }

  &__item {
    color: #000;
    font-size: initial;
    text-align: left;
    flex-basis: 26.5%;
    vertical-align: top;
    margin-bottom: 80px;

    &-img {
      width: 144px;
      height: 144px;
      margin-bottom: 24px;

      img {
        width: 144px;
        height: 144px;
        border-radius: 50%;
        object-fit: contain;
      }
    }

    &-name {
      font: 800 24px/1.5 $inter;
      margin-bottom: 16px;
    }

    &-text {
      font: 400 16px/1.5 $inter;
    }
  }
}

@media screen and (max-width: $tab-min) {

  .investors {

    .swc-title {
      padding-left: 0;
    }

    &__items {
      padding: 0;
      padding-bottom: 40px;
    }

    &__item {
      flex-basis: 47%;
    }
  }
}

@media screen and (max-width: $mob-max) {

  .investors {
    padding-bottom: 120px;

    .swc-title {
      font-size: 32px;
    }

    &__item {
      flex-basis: 100%;
    }
  }
}






































