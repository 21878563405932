.invest-block {
  //padding-top: 330px;
  //padding-bottom: 330px;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: #fff;

  .container {
    border-top: 1px solid #cacaca;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  //&__bg {
  //  height: 125%;
  //  width: 100%;
  //  position: absolute;
  //  top: -50%;
  //  left: 0;
  //  right: 0;
  //  margin: auto;
  //  //background: $main-blue url(../img/bg/invest-block.jpg) center no-repeat;
  //  background-size: cover;
  //  z-index: 0;
  //}

  &__info {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 880px;
    margin: auto;
  }

  &__text {
    font: 36px/1.56 $rl;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .invest-block {
    padding-top: 60px;
    padding-bottom: 60px;

    &__text {
      font: 23px/1.56 $rl;
      margin-bottom: 60px;
    }
  }

}











































