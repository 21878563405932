.transport-types {
  padding-top: 60px;
  background-color: #f4f4f4;

  &__slider {
  }

  &__slide {
    width: 100%;

    &:not(:first-child) {
      display: none;
    }
  }

  &__slide-top {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    //margin-bottom: 50px;

    img {
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }

    .swc-slider-arrow {
      position: absolute;
      top: 32%;
      z-index: 1;

      &_prev {
        left: 5px;
      }

      &_next {
        right: 5px;
      }
    }
  }

  &__slide-bot {
    font-size: 0;
    @include flex(row, space-between, flex-start);
    margin-top: 58px;

    &.container{
      max-width: 1420px;
    }
    &-title {
      font: 40px/1.2 $inter;
      margin-bottom: 16px;
      font-weight: 800;
    }

    &>* {
      font-size: initial
    }

    &-info {
      //width: 40%;
      padding-right: 20px;
      white-space: normal;

      p {
        font: 16px/2 $inter;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-video {
      display: inline-block;
      vertical-align: top;
    }

    &-gallery {
      //@include outr;
      display: inline-block;
      vertical-align: top;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;

      div {
        display: inline-block;
        vertical-align: top;
        width: 100%;
      }
    }

    &-imgs {
      white-space: nowrap;
      font-size: 0;
      //width: 60%;
      //@include flex(row, flex-end, center);
      //flex-wrap: wrap;
    }

    &-img {
      margin-right: 40px;
      margin-bottom: 40px;
      display: inline-block;
      vertical-align: top;
      max-width: 180px;
      max-height: 180px;
      cursor: pointer;

      &:last-child:not(:first-child) {
        margin-right: 0;
      }

      &_video {
        position: relative;

        &:before {
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          border: 3px solid #000;
          top: 0;
          left: 0;
          box-sizing: border-box;
        }

        &:after {
          position: absolute;
          display: block;
          content: '';
          width: 61px;
          height: 61px;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSI2MSIgdmlld0JveD0iMCAwIDYxIDYxIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iI0IwRUUzRiIgZD0iTTI5LjUtLjI1NmMxNi40MzQgMCAyOS43NTYgMTMuMzIyIDI5Ljc1NiAyOS43NTZTNDUuOTM0IDU5LjI1NiAyOS41IDU5LjI1Ni0uMjU2IDQ1LjkzNC0uMjU2IDI5LjUgMTMuMDY2LS4yNTYgMjkuNS0uMjU2eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyOSAtNDkxNSkgdHJhbnNsYXRlKDEzMCA0OTE2KSIvPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzAwMCIgZD0iTTM4LjYzMiAyOC4yNDhsLTExLjY3NS03LjQ4OWMtLjM0NS0uMjA5LS43NzMtLjE3NC0xLjA5Ny0uMTc0LTEuMjk1IDAtMS4yODkgMS4wMTctMS4yODkgMS4yNzV2MTUuMzA2YzAgLjIxOC0uMDA2IDEuMjc2IDEuMjg5IDEuMjc2LjMyNCAwIC43NTIuMDM0IDEuMDk3LS4xNzRsMTEuNjc1LTcuNDljLjk1OC0uNTguNzkyLTEuMjY0Ljc5Mi0xLjI2NHMuMTY2LS42ODYtLjc5Mi0xLjI2NnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjkgLTQ5MTUpIHRyYW5zbGF0ZSgxMzAgNDkxNikiLz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
    }
  }

  .slick-list.draggable {
    height: 100% !important;
  }
}

@media screen and (max-width: $tab-max) {

  .transport-types {
    &__slide-bot {
      @include flex(column, flex-start, flex-start);
      margin-top: 48px;
      &-info {
        width: 100%;
        padding-right: 0;
        margin-bottom: 48px;
      }

      &-imgs {
        width: 100%;
        @include flex(row, center, center);
      }

      &-img {
        margin-left: 0;
        margin-right: 40px;
      }
    }
    &__slide-top{
      .swc-slider-arrow{
        top: 15%;
      }
    }
  }

}

@media screen and (max-width: $tab-min) {

  .transport-types {
    &__slide-bot{
      &-title {
        font: 30px/1.2 $rb;
      }

      &-gallery {
        max-width: 215px;
      }

      &-img {
        margin-right: 15px;
        margin-bottom: 25px;
        width: 100px;
        height: 100px;

        img {
          width: 100%;
        }
      }
    }
    &__slide-top{
      .swc-slider-arrow{
        top: 15%;
      }
    }
  }

}

@media screen and (max-width: 480px) {
  .transport-types {
    &__slide-bot{
      margin-top: 123px;
    }
    &__slide-top{
      .swc-slider-arrow{
        top: 24%;
      }
    }
  }
}





































