.offer {

  &__body {
    max-width: 1280px;
    padding: 40px 139px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    color: #000;
  }

  &__title {
    font: 24px/36px $rb;
    text-align: center;
    margin-bottom: 30px;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    width: calc(33.33% - 5px);
    margin-bottom: 20px;
    max-width: 330px;
    box-sizing: border-box;

    &_radio {
      order: 4;
      margin-left: 37%;
    }

    &_button {
      order: 3;
      max-width: 262px;
    }
  }

  .swc-form-group {
    width: 100%;
  }

  .swc-btn {
    padding: 15px 12px;
    height: 50px;
    width: 100%;
  }

  .swc-form-input-error {
    font: 14px/18px $rr;
    color: #ff3f3f;
    margin-top: 4px;
  }

  .swc-radio__text a {
    color: #005bff;
  }
}

@media screen and (max-width: 1280px) {
  .offer__item_radio {
    margin-left: 35%;
  }
}

@media screen and (max-width: $tab-max) {
  .offer {

    &__body {
      padding: 50px 24px 30px;
    }

    &__item {

      &_radio {
        margin-left: 36%;
        width: 34%;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .offer__item_radio {
    margin-left: 34%;
  }
}

@media screen and (max-width: $tab-min) {
  .offer {

    &__form {
      flex-direction: column;
    }

    &__item {
      width: 100%;
      max-width: 100%;

      &_button {
        order: 4;
        max-width: 100%;
        margin-bottom: 0;
      }

      &_radio {
        order: 3;
        margin-left: 0;
      }
    }
  }
}