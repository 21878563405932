.team {
  &__block{
    background: #f4f4f4;
    position: relative;
    z-index: 0;
    padding-bottom: 60px;
    padding-top: 100px;
  }
  padding: 285px 70px;

  //background-attachment: fixed;
  color: white;
  position: relative;
  z-index: 1;
  //overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(0,0,0,.48);
  }

  &__title {
    font: 40px/1.5 $inter;
    margin: 0 auto 20px;
    max-width: 1060px;
    position: relative;
    text-align: center;
    font-weight: 800;
    z-index: 2;
  }
}

@media screen and (max-width: $tab-max) {
  .team {
    padding: 122px 70px;
    &__title {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $tab-min) {
  .team {
    padding: 98px 24px;
    &__block{
      & .swc-title{
        max-width: 628px;
        padding: 0 70px;
      }
    }
    &__title {
      font-size: 24px;
    }
  }
}
@media screen and (max-width: 600px) {
  .team {
    &__block{
      & .swc-title{
        padding: 0 24px;
        font-size: 32px;
      }
    }
  }
}















































