.paging {
  text-align: center;
  margin-bottom: 108px;

  &__page {
    font-family: $rr;
    font-size: 18px;
    color: #333;
    padding: 0 11px 5px;

    a {
      font-family: $rr;
      font-size: 18px;
      color: #333;
    }

    &_inactive {
      color: #c8c8c9;
    }

    &.active {
      color: #005bff;
      border-bottom: 2px solid #005bff;
      font-weight: bold;
    }
    &-leaf {
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 8px;
        margin: auto;
        width: 4px;
        height: 4px;
        border-bottom: 2px solid #333;
        border-left: 2px solid #333;
        left: 0;
        transform: rotate(45deg);
      }
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 10px;
        left: 1px;
        margin: auto;
        width: 14px;
        height: 2px;
        background-color: #333;
      }
      &.paging__page_inactive {
        &:before {
          border-bottom: 2px solid #c8c8c9;
          border-left: 2px solid #c8c8c9;
        }
        &:after {
          background-color: #c8c8c9;
        }
      }
    }
    &_prev {
      margin-right: 25px;
      position: relative;
      padding-left: 23px!important;
    }
    &_next {
      position: relative;
      margin-left: 25px;
      padding-right: 23px!important;

      &:before {
        left: auto;
        right: 0;
        transform: rotate(225deg);
      }
      &:after {
        left: auto;
        right: 1px;
      }
    }
  }
}

@media screen and (max-width: $tab-max) {

}

@media screen and (max-width: $tab-min) {
  .paging {
    margin-bottom: 60px;
    &__page {
      padding: 0 9px 5px;
      &_prev {
        margin-right: 15px;
      }
      &_next {
        margin-left: 15px;
      }
      &_srt {
        display: none;
      }
    }
  }

}
@media screen and (max-width: 480px) {
  .paging {
    &__page {
      padding: 0 9px 5px;
      &_prev {
        font-size: 14px;
        margin-right: 10px;
        padding: 0 0 0 17px!important;
        a{
          font-size: 14px;
        }
      }
      &_next {
        font-size: 14px;
        margin-left: 10px;
        padding: 0 17px 0 0!important;
        a{
          font-size: 14px;
        }
      }
      &-leaf{
        &:before{
          top: 5px;
        }
        &:after{
          top: 7px;
        }
      }
    }
  }

}