.creation {
  padding-top: 120px;
  padding-bottom: 160px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    background-color: #fff;
    max-width: 1320px;
    z-index: 1;
  }

  .swc-title {
    margin: 0;
    margin-bottom: 20px;
    padding-top: 120px;
    padding-left: 110px;
    text-align: left;
    font: 800 40px/1.5 $inter;
    letter-spacing: unset;
  }

  .swc-subtitle {
    width: 55%;
    margin: 0;
    margin-bottom: 80px;
    padding-left: 110px;
    text-align: left;
    font: 400 24px/1.5 $inter;
  }

  &__block {
    @include flex(row-reverse, space-between, center);
    margin-bottom: 81px;

    &:nth-child(odd) {
      padding-left: 110px;
      flex-direction: row;

      .creation__block-info {
        padding-right: 150px;
        padding-left: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-info {
      width: 50%;
      padding-left: 150px;
      padding-right: 110px;
    }

    &-video-wrap {
      //@include outg;
      width: 50%;
      min-width: 620px;
      max-width: 620px;
      //box-shadow: 0 0 30px 0 rgba(0,0,0,.2);
    }

    &-video {
      width: 100%;
      padding-top: 75%;
      position: relative;
      overflow: hidden;
      background-color: #d8d8d8;

      video {
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -100%;
        right: -100%;
        margin: auto;
        height: 100%;
      }

      img {
        width: 100%;
        display: none;
      }

      &-trigger {
        border: 1px solid red;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      }

      .swc-video-btn_play {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 50px;
        bottom: 50px;
        margin: auto;
        display: none;
      }
    }

    &-title {
      font: 800 24px/1.5 $inter;
      margin-bottom: 32px;
      color: #000;
    }

    &-text {
      font: 400 16px/1.5 $inter;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .creation {

    &__block {
      flex-direction: column;

      &:nth-child(odd) {
        flex-direction: column;

        .creation__block-info {
          width: 100%;
          padding: 0;
          max-width: 100%;
        }

        .creation__block-video-wrap {
          width: 100%;
          max-width: 100%;
        }
      }

      &-info {
        width: 100%;
        padding: 0;
      }

      &-video-wrap {
        width: 100%;
        max-width: 100% !important;
      }

      &-video {
        //width: 35%;
      }

    }

  }

}

@media screen and (max-width: $tab-min) {

  .creation {
    padding-top: 60px;
    padding-bottom: 0;

    .container {
      padding-bottom: 120px;
    }

    .swc-title {
      padding-left: 0;
    }

    .swc-subtitle {
      width: 100%;
      padding-left: 0;
    }

    &__block {
      flex-direction: column-reverse;
      //padding-bottom: 120px;

      &:nth-child(odd) {
        padding: 0;
        flex-direction: column-reverse;

        .creation__block-info {
          padding: 0;
          margin-top: 65px;
        }
      }

      .creation__block-info {
        padding: 0;
        margin-top: 65px;
      }

      &-video {
        img {
          display: block;
        }
      }

    }

    .swc-video-btn_play {
      display: block;
      right: 15px;
      bottom: 15px;
      //display: none!important;
    }

  }

}

@media screen and (max-width: 600px) {
  .creation {
    &__block {
      &-title {
        font-size: 24px;
      }

      &-text {
        font: 18px/1.78 $inter;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .creation {
    &__block {
      &-video-wrap {
        min-width: unset;
      }
    }
}
}








































