.partner-suggestion {
  padding-top: 120px;
  padding-bottom: 40px;
  //background-color: #0d2b67;
  color: white;

  .container {
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 40px/1.2 $rb;
    margin-bottom: 64px;
    text-align: center;
  }

  &__items {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
  }

  &__item {
    width: 50%;
    margin-bottom: 80px;
    padding-right: 40px;
    @include flex(row, flex-start, flex-start);

    &-img {
      display: block;
      margin-bottom: 20px;
      width: 70px;
      height: 70px;
      margin-right: 40px;
      flex-shrink: 0;
    }

    &-title {
      font: 24px $rb;
      margin-bottom: 16px;
    }

    &-text {
      font: 16px/1.5 $rr;
    }
  }
}

@media screen and (max-width: $tab-max) {



}

@media screen and (max-width: $tab-min) {

  .partner-suggestion {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      font: 30px/1.2 $rb;
    }

    &__item {
      width: 100%;
      padding-right: 0;
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }

      &-img {
        margin-right: 20px;
      }
    }
  }

}





































