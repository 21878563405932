.plug {
  margin-top: 140px;
  padding-top: 160px;
  padding-bottom: 200px;
  width: 100%;
  background-color: #f4f4f4;
  z-index: 99;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 120px;
    font: 900 64px/1.5 $inter;
  }

  &__content {
    position: relative;
    padding-left: 176px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 144px;
    height: 144px;
    border: 3px solid $btn;
    border-radius: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 105px;
      height: 3px;
      background-color: #000;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 105px;
      height: 3px;
      background-color: #000;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__text {
    margin-bottom: 16px;
    font: 800 24px/1.5 $inter;
  }

  &__message {
    font: 400 16px/1.5 $inter;
  }
}

@media screen and (max-width: $tab-min) {
  .plug {
    margin-top: 74px;
  }
}

@media screen and (max-width: $mob-max) {
  .plug {
    margin-top: 70px;
    padding-top: 120px;
    padding-bottom: 120px;

    &__title {
      margin-bottom: 80px;
      font-size: 44px;
    }

    &__content {
      padding-left: 0;
      padding-top: 176px;
    }

    &__icon {
      top: 0;
      transform: none;
    }
  }
}