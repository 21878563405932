.orgstructure {
  padding-top: 120px;
  padding-bottom: 105px;
  background-color: #f4f4f4;
  color: black;

  .container {
    max-width: 1060px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 900 64px/1.56 $inter;
    margin-bottom: 80px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex-basis: 48%;

    &:nth-child(odd) {
      margin-right: 35px;
    }

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    &-title {
      display: block;
      font: 800 24px $inter;
      margin-bottom: 15px;
    }

    &-text {
      display: block;
      font: 400 16px $inter;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

    }
  }
}

@media screen and (max-width: $tab-min) {
  .orgstructure {
    &__item {
      flex-basis: 100%;

      &:not(:last-child) {
        margin-right: unset;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .orgstructure {
    &__title {
      font: 900 44px/1.56 $inter;
    }
  }
}