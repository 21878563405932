.investorrelations {
  margin-top: 140px;
  padding-top: 160px;
  padding-bottom: 200px;
  width: 100%;
  background-color: #f4f4f4;
  z-index: 99;

  br {
    display: none;
  }

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 120px; // TODO - Этот временный большой отступ для отдаления заглушки (так тут отступ в 20)
    font: 900 64px/1.5 $inter;
  }

  &__content {
    margin-bottom: 160px;

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__list {
    margin-top: 80px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  &__link {
    font: 800 24px/1.5 $inter;
    color: #000;
    text-decoration: underline;
  }
}

@media screen and (max-width: $tab-min) {
  .investorrelations {
    margin-top: 74px;
  }
}

@media screen and (max-width: $mob-max) {
  .investorrelations {
    margin-top: 70px;
    padding-top: 120px;
    padding-bottom: 120px;

    br {
      display: block;
    }

    &__title {
      font-size: 36px;
    }
  }
}