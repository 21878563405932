.investment {
  padding-top: 160px;
  padding-bottom: 160px;
  width: 100%;
  background-color: #f4f4f4;
  z-index: 99;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 20px;
    font: 900 64px/1.5 $inter;
  }

  &__text {
    font: 400 24px/1.5 $inter;
  }
}

@media screen and (max-width: $tab-min) {
  .investment {
    &__text {
      br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .investment {
    padding-top: 120px;
    padding-bottom: 120px;

    &__title {
      font-size: 44px;
    }
  }
}