.header {

	&__nav {
		background-color: white;
		padding: 20px 0 18px;
		//box-shadow: 0 0px 10px rgba(0, 0, 0, .5);

		.container {
			padding: 0 80px;
			max-width: 1440px;
		}

		&-links {
			width: 100%;
			@include flex;
			flex-wrap: nowrap;
			
			&_mobile {
				display: none;
			}
		}

		&-link {
			font: 800 18px/28px $inter;
			white-space: nowrap;
			text-decoration: none!important;
			border-bottom: 2px solid transparent;
			color: #000;
			&:hover {
				border-bottom: 2px solid #000;
			}

			&.active {
				color: #000;
				border-bottom: 2px solid #000;
			}

			&-span {
				border-bottom: 2px dotted;
			}
		}

	}

}

@media screen and (max-width: $tab-max) {
	.header {

		&__nav {

			&-link {
				font-size: 16px;
			}

		}
	}

}

@media screen and (max-width: $tab-min) {
	.header {
		
		&__nav {
			background-color: #fff;
			display: none;
			padding-bottom: 25px;
			
			&-links {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: baseline;
				margin-bottom: 25px;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				&_mobile {
					display: flex;
				}
			}

			&-link {
				position: relative;
				color: black;
				font-size: 18px;
				font-family: $inter;
				padding: 15px 0;
    			text-align: left;
				border-bottom: none;

				&.active {
					border-bottom: none;

					&:before {
						content: "";
						position: absolute;
						left: 50%;
						bottom: 12px;
						width: calc(100% + 10px);
						height: 2px;
						background-color: #000;
						transform: translateX(-50%);
					}
				}

				&:hover {
					border-bottom: none;
				}
			}

		}
	}

}
