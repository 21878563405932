@import "fonts";
@import "font/stylesheet.css";
@import "mixin";
@import "reset";

@import "layout";
@import "swc";

@import "slick";
@import "tooltipster.bundle";
@import "etp-tooltipster.custom";
@import "fancybox";
@import "fancybox-etp";
@import "fancybox.custom";
@import "simple-scrollbar";
@import "intlTelInput";
@import "intlTelInput.custom";

@import "countdown";
@import "header";
@import "footer";
@import "nav";
@import "langs";
@import "content";
@import "preview";
@import "banner";
@import "news";
@import "media-articles";
@import "mass-media";
@import "webinar-reg";
@import "track-structure";
@import "tooltip";
@import "advantages";
@import "leadership";
@import "transport-types";
@import "shows";
@import "eco-techno-park";
@import "eco-techno-park-history";
@import "team";
@import "creation";
@import "video-banner";
@import "reg-form";
@import "download-presentation";
@import "consult-form";
@import "top-video";
@import "benefit";
@import "invest-block";
@import "chart";
@import "faq";
@import "why-to-invest";
@import "how-is-invest";
@import "how-to-invest";
@import "investors";
@import "reviews";
@import "invest-suggestion";
@import "investment";
@import "crowdinvesting";
@import "top-banner";
@import "partner-program";
@import "partner-suggestion";
@import "link-block";
@import "about-top";
@import "dynamic";
@import "personal-account";
@import "corporate-governance";
@import "plug";
@import "investorrelations";
@import "orgstructure";
@import "disclaimer";
@import "checkbox";
@import "how-it-works";
@import "leaders";
@import "contacts";
@import "paging";
@import "popup";
@import "subscribe";
@import "content-404";
@import "why-partner";
@import "scheme";
@import "leaflet";
@import "companion";
@import "offer";
@import "youtube-video";

.video-banner-wrap {
  background-color: #f4f4f4;
}
