.consult-form {
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: $main-blue;

  &__title {
    font: 24px/1.5 $rb;
    max-width: 620px;
    margin: 0 auto 50px;
    color: white;
    text-align: center;
  }

  &__form {
    @include flex(row, center, flex-start);
    flex-wrap: wrap;

    & a {
      color: #ffffff;
      text-decoration: underline;
    }

    & .swc-radio__text {
      line-height: 21px;
      text-align: left;
      margin-bottom: 10px;
    }

    .swc-form-label {
      color: white;
    }

    .swc-form-input {
      border: none;
    }

    .swc-btn {
      width: 100%;
    }

    .swc-radio__label {
      color: white;
    }
  }

  &__item {
    width: 33.33%;
    margin-bottom: 20px;

    &:not(:nth-child(3n)) {
      padding-right: 40px;
    }
    &:nth-child(4n) {
      margin-bottom: 0;
      text-align: center;
      a {
        color: #fff;
        text-decoration: underline;
      }
      .swc-form-group {
        display: inline-block;
        margin: 0 auto;
        width: auto;
      }
    }

    &_radio {
      order: 4;

      .swc-radio__open:after {
        right: 0;
      }
    }

    &_button {
      order: 3;
    }

    .swc-btn {
      margin-top: 23px;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .consult-form {

    &__item {

      &:not(:nth-child(3n)) {
        padding-right: 15px;
      }

      &_radio {

        .swc-radio__open:after {
          right: 0;
        }
      }
    }

  }

}

@media screen and (max-width: $tab-min) {

  .consult-form {

    &__form {
      max-width: 600px;
      margin: auto;
      flex-direction: column;
    }

    &__item {
      width: 100%;

      &_button {
        order: 4;
      }

      &_radio {
        margin-bottom: 0;
      }

      &:not(:nth-child(3n)) {
        padding: 0;
      }

      & .swc-btn {
        margin-top: 20px;
      }
    }

  }

}














































