.how-to-invest {
  padding-bottom: 160px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 80px;
    font: 800 40px/1.5 $inter;
  }

  &__list {
    @include flex(row, space-between, baseline);

    counter-reset: section;
  }

  &__item {
    flex-basis: 33.33%;

    &:not(:last-child) {
      margin-right: 95px;
    }

    &-num {
      &:before {
        display: block;
        padding-bottom: 16px;
        border-bottom: 2px solid #000;
        font: 800 24px/1.5 $inter;
        counter-increment: section;
        content: counter(section) "";
      }
    }

    &-text {
      display: block;
      margin-top: 24px;
      font: 400 16px/1.5 $inter;
    }
  }

  &__btn {
    margin-top: 48px;
  }
}

@media screen and (max-width: $tab-min) {
  .how-to-invest {
    &__title {
      padding-right: 70px;
    }

    &__item {
      flex-basis: unset;

      &:not(:last-child) {
        margin-right: unset;
        margin-bottom: 48px;
      }

      &-num {

        &:before {
          width: 260px;
        }
      }
    }

    &__list {
      @include flex(column, space-between, baseline);
    }
  }
}

@media screen and (max-width: $mob-max) {
  .how-to-invest {
    padding-bottom: 120px;

    &__title {
      font-size: 32px;
    }

    &__item {
      &-num {

        &:before {
          width: 100%;
        }
      }
    }

    .swc-btn {
      width: 100%;
    }
  }
}
