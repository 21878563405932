.chart {
  padding-top: 120px;
  padding-bottom: 135px;

  path {
    pointer-events:all;
    transition: all .2s ease;

    &:hover {
      fill: black;
    }
  }

  &__items {
    @include flex;
  }

  &__item {
    width: 50%;
    max-width: 600px;
    padding-left: 48px;
    text-align: center;

    &-img {
      position: relative;
      margin-bottom: 30px;

      img {
        width: 100%;
        max-width: 552px;
      }
    }

    &-y {
      font: 20px/1.6 $rl;
      color: #a1a1a1;
      position: absolute;
      left: 0;
      top: 45%;
      margin: auto;
      transform: rotate(-90deg);
      transform-origin: left bottom 0;
    }

    &-x {
      font: 20px/1.6 $rl;
      color: #a1a1a1;
    }

    &-text {
      font: 24px $rl;
    }
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .chart {
    padding-top: 60px;
    padding-bottom: 60px;

    &__items {
      @include flex(column, space-between, center);
    }

    &__item {
      width: 100%;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}