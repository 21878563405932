.subscribe {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 40px;
  margin-bottom: 108px;

  &__title {
    text-align: center;
    font: 24px/1.5 $rb;
    margin-bottom: 30px;
  }

  &__form {
    max-width: 950px;
    margin: auto;
    @include flex(row, center, flex-start);
    flex-wrap: wrap;

    &-item {
      width: 33.33%;

      &:last-child {
        padding-right: 0;
      }

      &-radio {
        margin-bottom: 0;
        width: 100%;
        max-width: 372px;
        order: 4;

      }

    }

    & .swc-radio__wrap:first-child {
      margin-top: 0;
    }

    &-item:not(:last-child) {
      padding-right: 40px;
    }

    .swc-btn {
      width: 100%;
      order: 3;
    }

    & .swc-radio__text {
      line-height: 21px;
    }
  }
}

@media screen and (max-width: $tab-max) {
  .subscribe {
    &__form {
      max-width: 330px;
      margin: auto;
      flex-direction: column;

      &-item {
        width: 100%;
        margin-bottom: 20px;

        &_button {
          order: 4;
          margin-top: 10px;
        }

        &:not(:nth-child(3n)) {
          padding-right: 0;
        }

        &:not(:last-child) {
          padding-right: 0;
        }

        &-radio {
          margin-left: 0;

          .swc-radio__open:after {
            right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $tab-min) {
  .subscribe {
    &__title{
      font-size: 22px;
      line-height: 1.55;
    }
  }
}