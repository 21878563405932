.leaflet {
  position: relative;
  margin: 14px 0px 6px;
  &__block {
    display: none;
    transition: all 2s linear;
    &_current {
      transition: all 20s linear;
      opacity: 100%;
      display: block;
    }
  }

  &__img {
    max-width: 1060px;
    margin: auto;
    min-width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    background-color: #fff;
  }
  &__description {
    max-width: 770px;
    margin-left: 94px;
    padding: 0;

    &__text {
      font-family: "Roboto-Italic", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.71;
      letter-spacing: normal;
      color: #959595;
      margin-top: 10px;
    }
  }
  .swc-slider{
    &-arrows {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
      position: absolute;
      top: 550px;
      right: 0;
    }

    &-arrow {
      width: 50px;
      height: 50px;
      position: relative;
      cursor: pointer;
      transition: all .2s ease;
      background: #fff;
      &:first-child{
        margin-right: 0;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        border-bottom: 3px solid #012169;
        border-left: 3px solid #012169;
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 20px;
        height: 3px;
        background-color: #012169;
      }

      &:hover {
        background: #012169;

        &:before {
          border-bottom-color: #fff;
          border-left-color: #fff;
        }

        &:after {
          background-color: #fff;
        }
      }
    }

    &-arrow {
      &_prev {
        &:before {
          left: 16px;
          transform: rotate(45deg);
        }
      }
    }

    &-arrow {
      &_next {
        &:before {
          right: 16px;
          transform: rotate(-135deg);
        }
      }
    }
  }
}
@media screen and (max-width: $tab-max) {
  .leaflet{
    margin: 6px 24px 6px;
    &__img{
      height: 453px;
    }
    &__description{
      margin-left: 0;
    }
    .swc-slider{
      &-arrows {
        top: 403px;
      }
    }
  }
}
@media screen and (max-width: $tab-min) {
  .leaflet{
    margin: 14px 16px 36px;
    &__img{
      height: 216px;
    }
    .swc-slider{
      &-arrows {
        top: 166px;
      }
    }
    .slick-dots{
      bottom: -64px;
    }
  }
}