.mass-media {
  padding-top: 160px;
  padding-bottom: 185px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  .swc-title {
    text-align: left;
    margin: unset;
    margin-bottom: 90px;
    letter-spacing: unset;
  }

  .slick-slide {
    padding: 0 48px;
  }

  &__numbers {
    font: 400 24px/1.5 $inter;
  }

  &__top {
    @include flex(row, space-between, center);
    margin-bottom: 40px;
  }

  &__title {
    font: 400 16px/1.5 $inter;
  }

  &__block {
    font-size: 0;
    padding-bottom: 30px;
    margin: 0 -48px;
  }

  &__item {
    width: 25%;
    font-size: initial;
    display: inline-block;
    //padding: 0 38px;
    position: relative;



    &-img {
      @include flex(column, space-between, baseline);
      justify-content: center;
      //height: 200px;
      //margin-bottom: 10px;
      //background-color: #d8d8d8;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 61px;
        height: 61px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSI2MSIgdmlld0JveD0iMCAwIDYxIDYxIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iI0IwRUUzRiIgZD0iTTI5LjUtLjI1NmMxNi40MzQgMCAyOS43NTYgMTMuMzIyIDI5Ljc1NiAyOS43NTZTNDUuOTM0IDU5LjI1NiAyOS41IDU5LjI1Ni0uMjU2IDQ1LjkzNC0uMjU2IDI5LjUgMTMuMDY2LS4yNTYgMjkuNS0uMjU2eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyOSAtNDkxNSkgdHJhbnNsYXRlKDEzMCA0OTE2KSIvPgogICAgICAgICAgICAgICAgPHBhdGggZmlsbD0iIzAwMCIgZD0iTTM4LjYzMiAyOC4yNDhsLTExLjY3NS03LjQ4OWMtLjM0NS0uMjA5LS43NzMtLjE3NC0xLjA5Ny0uMTc0LTEuMjk1IDAtMS4yODkgMS4wMTctMS4yODkgMS4yNzV2MTUuMzA2YzAgLjIxOC0uMDA2IDEuMjc2IDEuMjg5IDEuMjc2LjMyNCAwIC43NTIuMDM0IDEuMDk3LS4xNzRsMTEuNjc1LTcuNDljLjk1OC0uNTguNzkyLTEuMjY0Ljc5Mi0xLjI2NHMuMTY2LS42ODYtLjc5Mi0xLjI2NnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjkgLTQ5MTUpIHRyYW5zbGF0ZSgxMzAgNDkxNikiLz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==);
        top: 50px;
        left: 115px;
      }

      &:hover {
        text-decoration: none;
      }

      img {
        width: 290px;
        margin-bottom: 24px;
      }
    }

    &-title {
      display: block;
      width: 290px;
      font: 400 16px/1.5 $inter;
      color: #000;
    }

  }
}

@media screen and (max-width: $tab-min) {
  .mass-media {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: $mob-max) {
  .mass-media {
    padding-top: 0;

    .slick-slide {
      padding: 0;
    }

    &__block {
      margin: 0;
    }

    &__item {
      &-img {
        img {
          width: 100%;
        }
      }

      &-title {
        width: unset;
      }
    }
  }
}










































