.how-is-invest {
  padding-bottom: 80px;
  background-color: #f4f4f4;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 80px;
    margin-bottom: 80px;
    font: 800 40px/1.5 $inter;
  }

  &__list {
    @include flex(row, flex-start, center);
  }
  
  &__item {
    position: relative;
    padding-left: 84px;
    flex-basis: 32%;

    &:not(:last-child) {
      &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 69px;
        width: 1px;
        height: 18px;
        background-color: #000;
        transform: rotate(45deg);
      }

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        right: 69px;
        width: 1px;
        height: 18px;
        background-color: #000;
        transform: rotate(-45deg);
      }
    }

    &-block-image {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 64px;
        height: 64px;
        background-color: #fff;
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }

    &-title {
      font: 800 16px/1.5 $inter;
    }

    &-image {
      position: absolute;
      top: 50%;
      left: 21px;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: $tab-min) {
  .how-is-invest {
    .container {
      padding-right: 0;
    }

    &__title {
      padding-right: 70px;
    }

    &__item {
      padding-left: 0;
      padding-top: 78px;
      flex-basis: 31%;

      &-image {
        position: absolute;
        top: 20px;
        left: 21px;
        transform: unset !important;
      }

      &:not(:last-child) {
        &:before {
          bottom: 55px;
          right: 77px;
        }

        &:after {
          top: 17px;
          right: 77px;
        }
      }

      &-block-image {
        &:before {
          top: 0;
          transform: translateY(0);
        }
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .how-is-invest {
    &__title {
      font-size: 32px;
    }

    &__list {
      flex-direction: column;
      align-items: flex-start;
    }

    &__item {
      display: flex;
      width: 100%;
      min-height: 64px;
      align-items: center;
      padding-top: 0;
      padding-left: 84px;
      flex-basis: unset;

      &:not(:last-child) {
        margin-bottom: 63px;

        &:before {
          bottom: -42px;
          left: 38px;
          right: 0;
        }

        &:after {
          top: 89px;
          left: 25px;
          right: 0;
        }
      }

      &-block-image {
        &:before {
          top: 0;
          transform: translateY(0);
        }
      }
    }
  }
}



































