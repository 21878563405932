.partner-program {
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: white;

  &__title {
    font: 40px/1.2 $rb;
    margin-bottom: 64px;
    text-align: center;
  }

  &__items {
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
  }

  &__item {
    //@include outr;
    width: 33.33%;
    margin-bottom: 80px;
    padding-right: 40px;

    &-img {
      //@include outr;
      width: 70px;
      height: 70px;
      display: block;
      margin-bottom: 20px;
    }

    &-title {
      font: 24px $rb;
      margin-bottom: 16px;

      &-link {
        display: block;
        font: 24px $rr;
        color: $main-blue-light;
        text-decoration: underline;
      }
    }

    &-text {
      font: 16px/1.5 $rr;
    }
  }
}

@media screen and (max-width: $tab-max) {

  .partner-program {

    &__item {
      width: 50%;
    }
  }

}

@media screen and (max-width: $tab-min) {

  .partner-program {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      font: 30px/1.2 $rb;
    }

    &__item {
      //@include outr;
      width: 100%;
      max-width: 570px;
      margin: 0 auto 40px;
      text-align: center;
      padding-right: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &-img {
        //@include outr;
        margin: 0 auto 20px;
      }
    }
  }

}










































