.media-articles {
	
	padding-top: 50px;
	padding-bottom: 100px;
	background-color: white;
	
	&__top {
		@include flex;
		margin-bottom: 60px;
	}
	
	&__title {
		font-family: $rb;
		font-size: 30px;
		color: $main-blue;
	}
	
	&__block {
//		@include flex;
		font-size: 0;
		padding-bottom: 30px;
//		white-space: nowrap;
//		overflow: hidden;
	}
	
	&__item {
		width: 25%;
		text-align: center;
		font-size: initial;
		display: inline-block;
//		margin-bottom: 30px;
//		white-space: normal;
		
		&-img {
			@include flex(column, space-between, center);
			align-items: center;
			justify-content: center;
			height: 120px;
			border-left: 1px solid #e5e5e5;
			border-right: 1px solid #e5e5e5;
			margin-bottom: 10px;
			padding: 0 10px;

			img {
				width: 100%;
				max-width: 250px;
				max-height: 100px;
			}
		}
		
		&-text {
			display: block;
			color: black;
			font-size: 14px;
			line-height: 1.5;
			padding: 0 10px;
			
			&:hover {
				color: $main-blue;
			}
		}
	}
	
}

@media screen and (max-width: $tab-min) {
	.media-articles {
		&__arrows {
			display: none;
		}
		
		&__block {
			padding-bottom: 40px;
		}
	}
}































