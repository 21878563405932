.tooltip {
  &_templates {
    display: none;
  }

  &__video {
    width: 510px;
    //padding-top: 56.2%;
    position: relative;

    video {
/*      position: absolute;
      top: 0;
      left: 0;
      width: 100%;*/
    }
  }
  
  &__h {
    display: block;
    font: 24px/1.33 $rb;
    color: #000;
    margin-bottom: 20px;
  }

  &__txt {
    font: 16px/1.75 $rr;
    color: #1b1b1b;

    p {
      margin-bottom: 10px;
    }
  }

  &__info {
    padding: 20px;
    font: 16px/1.5 $rr;
    display: block;
  }

  &__img {
    width: 100%;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .tooltip {
    &__video {
      width: 100%;
      //height: 158px;
    }
  }

}