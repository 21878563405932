.personal-account {
  position: relative;
  padding-top: 160px;
  padding-bottom: 105px;
  //background: url("../img/bg/earth.jpg") no-repeat top center;
  //background-size: cover;
  color: black;
  z-index: 2;

  &:before {
    content: "";
    position: absolute;
    top: 160px;
    right: 0;
    width: 480px;
    height: 720px;
    background-image: url("/landingassets/img/bg/earth.jpg");
  }

  .container {
    max-width: 1060px;
    position: relative;
    z-index: 1;
  }

  &__title {
    font: 800 36px/1.56 $inter;
    margin-bottom: 60px;
    max-width: 720px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    font-size: initial;
    display: inline-block;
    vertical-align: top;
    flex-basis: 44.5%;
    padding-left: 20px;
    position: relative;
    margin-bottom: 55px;
    padding-right: 40px;

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: black;
      top: 10px;
      left: 0;
    }

    &:nth-child(odd) {
      margin-right: 78px;
    }

    &-title {
      font: 800 24px $inter;
      margin-bottom: 15px;
      display: block;
    }

    &-text {
      font: 16px/1.5 $inter;
      display: block;
    }
  }

  &__btn {
    padding-left: 20px;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .personal-account {
    padding-top: 160px;
    padding-bottom: 160px;

    &:before {
      width: 396px;
      height: 720px;
    }

    &__item {
      flex-basis: unset;

      &:nth-child(odd) {
        margin-right: 0;
      }
    }

    &__title {
      font: 800 40px/1.56 $inter;
    }
  }
}

@media screen and (max-width: $mob-max) {
  .personal-account {
    padding-top: 120px;
    padding-bottom: 120px;

    &:before {
      width: 280px;
      height: 720px;
    }

    &__title {
      font: 800 32px/1.56 $inter;
    }
  }
}
































