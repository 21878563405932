.corporate-governance {
  margin-top: 140px;
  padding-top: 160px;
  padding-bottom: 200px;
  width: 100%;
  background-color: #f4f4f4;
  z-index: 99;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    margin-bottom: 20px;
    font: 900 64px/1.5 $inter;
  }

  &__text {
    font: 400 24px/1.5 $inter;
  }

  &__content {
    margin-top: 80px;
  }

  &__subtitle {
    margin-bottom: 50px;
    font: 800 40px/1.5 $inter;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;

    &--even {
      .corporate-governance__item {
        &:nth-child(even) {
          margin-right: 35px;
        }

        &:nth-child(odd) {
          margin-right: 0 !important;
        }
      }
    }

    .corporate-governance__item {
      display: flex;
      flex-direction: column;
      flex-basis: 48%;

      &--full {
        flex-basis: 100%;
      }

      &:nth-child(odd) {
        margin-right: 35px;
      }

      &-title {
        display: block;
        font: 800 24px $inter;
        margin-bottom: 15px;
      }

      &-text {
        display: block;
        font: 400 16px $inter;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

      }
    }
  }

  &__list {
    margin-top: 80px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }

  &__link {
    font: 800 24px/1.5 $inter;
    color: #000;
    text-decoration: underline;
  }

  &__practices {
    margin-bottom: 160px;

    span {
      display: block;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__report {
    margin-bottom: 160px;
  }

  &__audit {
    margin-bottom: 160px;

    .audit {
      margin-top: 80px;

      &__block {
        margin-bottom: 80px;
      }

      &__title {
        margin-bottom: 48px;
        font: 800 40px/1.5 $inter;
      }

      &__text {
        font: 400 16px/1.5 $inter;
      }
    }
  }
}

@media screen and (max-width: $tab-min) {
  .corporate-governance {
    margin-top: 74px;

    &__items {
      .corporate-governance__item {
        flex-basis: 100%;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .corporate-governance {
    margin-top: 70px;
    padding-top: 120px;
    padding-bottom: 120px;

    &__title {
      font-size: 44px;
    }

    &__subtitle {
      font-size: 32px;
    }

    &__practices {
      margin-bottom: 120px;
    }

    &__report {
      margin-bottom: 120px;
    }

    &__audit {
      margin-bottom: 120px;

      .audit {
        &__title {
          font-size: 32px;
        }
      }
    }
  }
}