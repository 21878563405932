.why-to-invest {
  padding: 160px 190px;
  margin-bottom: 160px;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__text {
    color: #fff;
    font: 800 40px/1.5 $inter;
  }

  .swc-btn {
    display: block;
    margin-top: 40px;
  }
}

@media screen and (max-width: $tab-min) {

  .why-to-invest {
    padding: 160px 0;
  }
}

@media screen and (max-width: $mob-max) {

  .why-to-invest {
    padding: 120px 0;
    padding-bottom: 120px;

    &__text {
      font-size: 32px;
    }
  }
}