.swc-checkbox {
  position: relative;
  display: block;
  padding: 0;
  padding-left: 40px;
  user-select: none;
  cursor: pointer;
  margin-bottom: 24px;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  &__input {
    position: absolute;
    bottom: 100%;
    right: 100%;
    opacity: 0;

    &:checked + .swc-checkbox__icon,
    &.checked + .swc-checkbox__icon {
      background: #000;

      &:before {
        opacity: 1;
      }
    }
  }

  &.disabled &__icon,
  &__input:disabled ~ &__icon {
    background: #f5f5f5;
    border: 1px solid #dadada;
    cursor: default;
    pointer-events: none;
  }

  &.disabled &__label,
  &__input:disabled ~ &__label {
    color: black;
    cursor: default;
    pointer-events: none;
  }


  &.checked.disabled &__icon,
  &__input:checked:disabled ~ &__icon {
    background: #cccccc;
    border: 1px solid transparent;
    pointer-events: none;

    &:before {
      opacity: 1;
    }
  }
  &.checked.disabled &__label,
  &__input:checked:disabled ~ &__label {
    pointer-events: none;
  }

  &__label {
    font: 400 16px/1.5 $inter;
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 1px;
    top: 2px;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 1px solid #000;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 8px;
      border-bottom: 3px solid $btn;
      border-left: 3px solid $btn;
      opacity: 0;
      transform: rotate(-50deg);
      transition: all .2s;
    }
  }

  &_white{
    .swc-checkbox{
      &__icon{
        background-image: none;
        border-color: rgba(255, 255, 255, 0.5);
      }
      &__label{
        color: #fff;
      }
    }
    .swc-checkbox__input{
      &:disabled {
        &~ .swc-checkbox__icon {
          background-color: rgba(255, 255, 255, 0.2);
          border-color: transparent;
        }
        &~ .swc-checkbox__label{
          color: rgba(255, 255, 255, 0.2);
        }
      }

      &:checked:disabled {
        &~ .swc-checkbox__icon {
          background: rgba(255, 255, 255, 0.2);
          border-color: transparent;

          &:before{
            border-bottom: 2px solid rgba(1, 33, 105, 0.4);
            border-left: 2px solid rgba(1, 33, 105, 0.4);
          }
        }
      }
    }
  }
  &_separated {
    border-top: 1px solid #dadada;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 35px;
    padding-right: 10px;

    &:last-child {
      border-bottom: 1px solid #dadada;
    }

    .swc-checkbox__icon {
      top: 11px;
      left: 10px;
    }
  }

  &_hidden {
    display: none;
  }
  &-slider {
    position: relative;
    padding-left: 44px;
    user-select: none;
    cursor: pointer;
    margin: 0;

    &__input {
      display: none;
    }

    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 36px;
      height: 20px;
      border-radius: 10px;
      background: #b0c5ec;
      transition: all .2s ease;

      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        background: #fff;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        transition: all .2s ease;
      }
    }

    &__label {
      font: 14px/20px LatoWeb,sans-serif;
      color: #7e98c6;
      flex: 1 0 100%
    }

    &__input:checked ~ .swc-checkbox-slider__icon {
      background: $btn;

      &:before {
        left: calc(100% - 18px);
      }
    }

    &__input:checked ~ .swc-checkbox-slider__label {
      color: $btn;
    }

    & + .swc-icon {
      margin-left: 8px;
    }
  }
}