.reg-form {
  padding-top: 115px;
  padding-bottom: 80px;
  //background: url(../img/bg/reg.jpg) center no-repeat;
  //background-size: cover;
  //background-color: #fff;
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.32);
    z-index: 1;
  }

  &_about {
    //background: url(../img/bg/reg-about.jpg) center bottom no-repeat;
    //background-size: cover;

    &:before {
      display: none;
    }
  }

  video {
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -100%;
    right: -100%;
    margin: auto;
    min-height: 100%;
    min-width: 100%;
    z-index: 0;
  }

  &__form {
    max-width: 620px;
    margin: auto;
    padding: 60px 20px 80px;
    background-color: white;
    position: relative;
    z-index: 1;

    &-body {
      //@include outg;
      max-width: 400px;
      margin: auto;
    }
  }

  .swc-btn {
    width: 100%;
    margin-top: 10px;
  }

  &_title {
    position: relative;
    z-index: 1;
    max-width: 840px;
    margin: 110px auto;

    &-text {
      font-family: $inter;
      font-size: 64px;
      line-height: 1.5;
      color: #fff;
      margin-bottom: 65px;
      font-weight: 900;
    }
    &-btn {
      width: 290px !important;
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
  .swc-form-group{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: $tab-max) {
  .reg-form {

    &_title {
      &-text {
        font-size: 44px;
        margin-bottom: 64px;
      }
    }

  }
}

@media screen and (max-width: $tab-min) {

  .reg-form {
    padding-top: 60px;
    padding-bottom: 60px;
    background: url(../img/bg/reg-form.jpg) center no-repeat;
    background-size: cover;

    &_title {

      &-text {
        font-size: 28px;
      }
      &-btn{
        width: 100% !important;
      }
    }

    video {
      display: none;
    }
  }

}