.top-banner {
  padding-top: 270px;
  padding-bottom: 270px;
  position: relative;
  overflow: hidden;
  //background: url("../img/bg/for-partners.png") no-repeat center;
  //background-size: cover;

  //&:before {
  //  display: block;
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  background-image: linear-gradient(to right, rgba(0, 48, 135, 0), rgba(0, 48, 135, 0.65));
  //  z-index: 1;
  //}
  //
  //img {
  //  position: absolute;
  //  top: -100%;
  //  bottom: -100%;
  //  left: -100%;
  //  right: -100%;
  //  margin: auto;
  //  min-height: 100%;
  //  min-width: 100%;
  //}

  &__text {
    position: relative;
    width: 100%;
    max-width: 1060px;
    margin: auto;
    text-align: center;
    color: white;
    font: 36px/1.56 $rb;
    z-index: 2;
  }
}

@media screen and (max-width: $tab-max) {}

@media screen and (max-width: $tab-min) {

  .top-banner {
    padding-top: 120px;
    padding-bottom: 120px;

    &__text {
      font-size: 24px;
    }

  }

}





































