.header {
	//	@include outg;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	transition: top .35s ease;
	z-index: 100;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
	background: #fff;
	& .container{
		max-width: 1440px;
	}
	&_shadow {
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .1);
	}

	&_hidden {
		top: -500px;
		bottom: 100%;
	}

	&__top {
		background-color: #fff;
		padding: 21px 0;
		color: #000;
		max-width: 1440px;
		margin: auto;
		.container {
			@include flex;
			flex-wrap: nowrap;
			padding: 0 80px;
		}

		a {
			color: #000;
		}
	}
	&__line {
		border-left: solid #fff 80px;
		border-right: solid #fff 80px;
		max-width: 1440px;
		margin: auto;
		height: 1px;
		background: #e0e0e0;
	}
	&__call {
		@include inline-blocks;
	}

	&__phone {
		font-size: 18px;
	}

	&__callback {
		cursor: pointer;
		font-size: 18px;
		border-bottom: 2px dotted;
		font-family: $rr;
		text-decoration: none!important;
	}

	&__account {
		@include inline-blocks;
	}

	&__login {
		font-size: 18px;
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid;
	}

	&__reg {
		font-size: 18px;
		margin-right: 20px;
	}



	&__logo-img {
		display: block;
		width: 82px;
		height: 28px;
	}

	&__menu {
		display: none;
		width: 32px;
		height: 24px;
		position: relative;
		transform: rotate(0deg);
		transition: .1s ease-in-out;
		cursor: pointer;
		user-select: none;
		position: absolute;
		top: 23px;
		left: 24px;
		i {
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: #000;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .2s ease-in-out;

			&:nth-child(1) {
				top: 0px;
				transform-origin: left center;
			}

			&:nth-child(2) {
				top: 0;
				bottom: 0;
				margin: auto;
				transform-origin: left center;
			}

			&:nth-child(3) {
				bottom: 0;
				transform-origin: left center;
			}
		}

		&.open {
			i {
				&:nth-child(1) {
					transform: rotate(45deg);
					top: -2px;
					left: 5px;
				}

				&:nth-child(2) {
					width: 0%;
					opacity: 0;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					bottom: -1px;
					left: 5px;
				}
			}
		}
	}
}

@media screen and (max-width: $tab-max) {
	.header {
		&__phone,
		&__callback,
		&__login,
		&__reg {
			font-size: 16px;
		}

		&__phone,
		&__login {
			padding-right: 8px;
			margin-right: 8px;
		}

		&__logo-img {
			height: 18px;
		}
		&__reg {
			margin-right: 12px;
		}

	}
}

@media screen and (max-width: $tab-min) {
	.header {

		&.open {
			bottom: 0;
			overflow-y: auto;
			background-color: #fff;
		}
		&__top {
			.container {
				padding: 0 24px;
				justify-content: flex-end;
			}
		}
		&__call {
			display: none;
		}

		&__logo{
			position: absolute;
			left: 104px;
			&-img {
				height: 28px;
			}
		}

		&__line{
			display: none;
		}

		&__login, &__reg {
			display: none;
		}
		&__menu{
			display: block;
		}
	}
}
@media screen and (max-width: 480px) {

	.header {
		&__logo{
			left: 50%;
			margin-left: -41px;
			&-img {
				height: 28px;
			}
		}
	}

}
@media screen and (min-width: $tab-min) {

	.header {
		&__nav {
			display: block!important;
		}

	}

}
