/* adaptive video */
.youtube-video {
  position: absolute;
  bottom: 0;
  right: 1px;
  left: 0;
  top: 0;

  background: center center no-repeat;
  background-size: cover;
  cursor: pointer;
}
.youtube-video iframe {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #000;
}
.youtube-video iframe[src=""] {
  display: none;
}
.youtube-video video {
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
}