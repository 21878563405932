.header {

	&__lang {
		cursor: pointer;
		user-select: none;
		position: relative;

		&-img {
			width: 48px;
			height: 32px;
			vertical-align: middle;
		}

		&-text {
			margin-left: 8px;
			font: 600 16px $inter;
			vertical-align: middle;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__nav {

		&-langs-wrap {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 999;
			background-color: white;
			height: 100%;
			overflow: auto;
		}

		&-langs {
			//@include outr;
			//@include flex(row, flex-start, center);
			//flex-wrap: wrap;
			//text-align: center;
			max-width: 1020px;
			margin: auto;
			margin-top: 65px;
			font-size: 0;

			&-top {
				position: relative;
				@include flex;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					background-color: #e0e0e0;
				}
			}

			&-title {
				font: 800 24px/1.22 $inter;
				padding: 20px 0;
				color: black;
				text-align: center;
				margin: 0 15px;
			}

			&-close {
				width: 64px;
				height: 64px;
				cursor: pointer;
				position: relative;

				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					height: 4px;
					width: 50px;
					border-radius: 2px;
					background: black;
					opacity: 1;
					left: 0;
					transform: rotate(0deg);
					transform-origin: left center;
				}
				&:before {
					transform: rotate(45deg);
					top: 12px;
					left: 15px;
				}
				&:after {
					transform: rotate(-45deg);
					bottom: 12px;
					left: 15px;
				}
			}
		}

		&-lang {
			//@include outw;
			font-size: initial;
			display: inline-block;
			position: relative;
			padding: 18px 32px;
			padding-left: 0;
			width: 10%;
			text-align: left;
			white-space: nowrap;
			min-width: 180px;
			margin-right: 22px;
			color: black;
			//flex-grow: 1;
			border: 2px solid transparent;
			transition: all .2s ease;
			cursor: pointer;

			&:nth-child(5) {
				margin-right: 0;
			}

			&.current {
				&:before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
					background-color: #000000;
				}
			}

			&:hover {
				&:before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2px;
					background-color: $btn;
				}
			}

			&-text {
				font: 18px $inter;
			}
		}

	}

}

@media screen and (max-width: $tab-max) {
	.header {

		&__nav {

			&-langs {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0 100px;
			}
		}
	}
}

@media screen and (max-width: $tab-min) {

	.header {

		&__nav {

			&-langs {
				//@include flex(row, space-around, center);
				margin-top: 45px;
				justify-content: space-between;
				padding: 0;

				&-title {
					font: 800 24px/1.22 $inter;
					padding: 20px 0;
					margin: 0;
				}

				&-close {
					width: 32px;
					height: 32px;
					background: transparent;

					&:before, &:after {
						height: 4px;
						border-radius: 0;
						width: 100%;
					}
					&:before {
						transform: rotate(45deg);
						top: 5px;
						left: 5px;
					}
					&:after {
						transform: rotate(-45deg);
						bottom: 0px;
						left: 5px;
					}
				}
			}

			&-lang {
				//@include outw;
				font-size: initial;
				display: inline-block;
				padding: 10px 32px;
				padding-left: 0;
				width: 10%;
				text-align: left;
				white-space: nowrap;
				min-width: 180px;
				color: black;
				margin-top: 5px;
				margin-bottom: 5px;
				margin-right: 0;
				//flex-grow: 1;
				border: 2px solid transparent;
				transition: all .2s ease;
			}
		}

	}

}

@media screen and (max-width: $mob-max) {
	.header {
		&__nav {
			&-langs {
				margin-left: 24px;
				margin-right: 24px;
			}

			&-lang {
				width: 100%;
			}
		}
	}
}





































