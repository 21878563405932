.about-top {
  padding-top: 96px;
  padding-bottom: 234px;
  //background: url("../img/bg/about-top.jpg") no-repeat center;
  //background-size: cover;
  color: white;

  .container {
    position: relative;
    max-width: 1060px;
    z-index: 1;
  }

  &__title {
    font: 900 24px/1.5 $inter;
    margin-bottom: 144px;
    text-align: left;
  }

  &__text {
    margin: auto;
    text-align: center;
  }

  p {
    font: 900 44px/1.56 $inter;
    display: block;
    text-align: left;
  }
}

@media screen and (max-width: $tab-min) {

  .about-top {
    padding-top: 60px;
    padding-bottom: 60px;

    &__title {
      margin-bottom: 145px;
    }

    p {
      font: 900 44px/1.56 $inter;
      margin-bottom: 44px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $mob-max) {
  .about-top {

    &__title {
      margin-bottom: 160px;
    }

    p {
      font: 900 28px/1.56 $inter;
      margin-bottom: 44px;
    }
  }
}











































