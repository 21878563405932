@font-face {
    font-family: 'Roboto Thin';
    font-display: auto;
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Light';
    font-display: auto;
    src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Regular';
    font-display: auto;
    src: local('Roboto Regular'), local('Roboto-Regular'), url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Medium';
    font-display: auto;
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Bold';
    font-display: auto;
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Black';
    font-display: auto;
    src: local('Roboto Black'), local('Roboto-Black'), url('../fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif';
    font-display: auto;
    //src: url('../fonts/NotoSerif/NotoSerif.eot');
    src: local('Noto Serif'), local('NotoSerif'),
    url('../fonts/NotoSerif/NotoSerif.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSerif/NotoSerif.woff') format('woff'),
    url('../fonts/NotoSerif/NotoSerif.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif Italic';
    src: url('../fonts/NotoSerif/NotoSerif-Italic.eot');
    src: local('Noto Serif Italic'), local('NotoSerif-Italic'),
    url('../fonts/NotoSerif/NotoSerif-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSerif/NotoSerif-Italic.woff') format('woff'),
    url('../fonts/NotoSerif/NotoSerif-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Noto Serif Bold';
    src: url('../fonts/NotoSerif/NotoSerif-Bold.eot');
    src: local('Noto Serif Bold'), local('NotoSerif-Bold'),
    url('../fonts/NotoSerif/NotoSerif-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSerif/NotoSerif-Bold.woff') format('woff'),
    url('../fonts/NotoSerif/NotoSerif-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Noto Serif Bold Italic';
    src: url('../fonts/NotoSerif/NotoSerif-BoldItalic.eot');
    src: local('Noto Serif Bold Italic'), local('NotoSerif-BoldItalic'),
    url('../fonts/NotoSerif/NotoSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/NotoSerif/NotoSerif-BoldItalic.woff') format('woff'),
    url('../fonts/NotoSerif/NotoSerif-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}