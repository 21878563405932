.preview {
	//	@include outr;
	color: white;
	//background-color: $main-blue;
	overflow: hidden;
	position: relative;
	
	&-wrap {
		@include flex(row, space-between, initial);
	}

	&__main {
		width: 100%;
		
		&-wrap {
			position: relative;
			width: 65%;
			@include flex(row, flex-end, center);
			box-sizing: border-box;
			padding: 150px 30px 150px 5%;
			//background-image: url(../img/preview/1.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			&:before {
				content: '';
				position: absolute;
				display: block;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-image: linear-gradient(to top, rgba(0, 48, 135, 0), #003087);
				opacity: .65;
				z-index: 1;
			}

			div {
				width: 100%;

			}
			&_news {
				div {
					min-height: 100%;
				}
			}
			img	{
				max-width: 100%;
			}

			&_full {
				padding-top: 194px;
				padding-bottom: 194px;
				width: 100%;
				text-align: center;

				.preview__main {
					max-width: 730px;
					margin: auto;

					&-title {
						font-size: 36px;
						margin-bottom: 20px;
						line-height: 1.22;
					}

					&-text {
						font-size: 24px;
						line-height: 1.33;
						margin-bottom: 40px;
					}
				}

				&:before {
					opacity: .5;
					background-image: linear-gradient(rgba(0, 48, 135, 0.2), rgba(0, 48, 135, 0.2)), linear-gradient(to top, rgba(0, 48, 135, 0), #003087);
				}
			}

			&_no-pad {
				padding: 0;
			}

			&_no-gradient {
				background: none;
				background-color: #fafbfc;

				&:before {
					background-image: none;
					opacity: 1;
				}
			}
			
			& > * {
				position: relative;
				z-index: 1;
			}
		}

		&-title {
			font: 36px/1.22 $rb;
			margin-bottom: 20px;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
		}

		&-text {
			font: 24px $rr;
			margin-bottom: 80px;
			text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
		}
		&-img {
			display: inline-block;
			min-width: 100%;
			height: 472px;
			background-size: cover;
			background-position: center;
			background-color: rgba(0,48,135,.55);
		}
	}

	&__list {
		margin-bottom: 40px;
		text-align: left;

		li {
			font: 20px/1.55 $rr;
			margin-bottom: 15px;
			position: relative;
			padding-left: 25px;

			&:before {
				display: block;
				content: '';
				position: absolute;
				top: 14px;
				left: 8px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: white;
			}
		}

	}
	
	&__events {
		width: 35%;
		background-color: $main-blue;
		position: relative;
		white-space: nowrap;
		
		&:after {
//			outline: 1px solid #c00;
			position: absolute;
			content: '';
			display: block;
			height: 100%;
			width: 200%;
			top: 0;
			bottom: 0;
			left: 100%;
			margin: auto;
			background-color: $main-blue;
		}
		
		.swc-slider-arrows {
			position: absolute;
			left: 40px;
			bottom: 40px;
		}
		
		&-count {
			font-size: 20px;
			font-family: $rb;
			position: absolute;
			bottom: 40px;
			right: 40px;
			line-height: 50px;
			
			span {
				font-size: 20px;
				font-family: $rb;
			}
		}

		&_reverse {
			background-color: #fafbfc;

			.preview__event{
				&-title {
					color: #000;
				}
				&-text {
					font-family: $rr;
					font-size: 18px;
					line-height: 1.78;
					color: #000;
				}
				&-body {
					//padding-top: 0;
				}
			}
			.preview__events-count {
				font-family: $rm;
				font-size: 20px;
				color: $main-blue;
				bottom: 0;
			}
			.swc-slider-arrows {
				bottom: 0;
			}
		}
	}

	&__event {
		width: 100%;
		white-space: normal;
		display: inline-block;
		
		&-img {
			//height: 300px;
			padding-top: 50%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			
			img {
				width: 100%;
			}
		}
		
		&-body {
			padding: 0px 40px 150px 40px;
			width: 100%;

			&_counter {
				padding: 150px 40px 150px 40px;
			}

			.swc-btn {
				margin-top: 35px;
			}
			.event{
				&-info{
					&__data{
						margin-right: 23px;
					}
				}
			}
		}

		&-info {
/*			@include flex(row, flex-start, baseline);

			.preview__event-data {
				display: inline-block;
				font-family: $rr;
				font-size: 14px;
				line-height: 1.71;
				color: #9f9f9f;
				margin-right: 23px;
			}*/
		}

/*		&-category {
			display: inline-block;
			font-family: $rr;
			font-size: 12px;
			color: #003087;
			padding: 6px 13px 2px;
			margin-right: 10px;
			border-radius: 2px;
			border: solid 1px rgba(0, 48, 135, 0.14);
			text-decoration: none;

			&:hover {
				background-color: #012169;
				color: #fff;
				border: 0 none;
				padding: 7px 14px 3px;
				text-decoration: none;
			}
		}*/

/*		&-data {
			font-family: $rr;
			font-size: 16px;
			line-height: 1.5;
			color: #9f9f9f;
			margin-bottom: 8px;
		}

		&-view-counter {
			display: inline-block;
			font-family: $rr;
			font-size: 14px;
			line-height: 1.71;
			color: #adadad;

			padding-left: 26px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAABLUlEQVR42qVTJVQAQRSchPaCNazh7u4kHo0Mvef7i1ccGo9Gu4xLRnsh487s4G5zst//rOFDZLkCZNgkMm0fmcEx5SNkBHvUx5FtJfgWub0ZDA6ZdMtxE1k2zLFHH2XZ5Avm2SwdHyLD2hl4wsA1ZLtK2er6YsVA30gMxDaoUkyGnbF4K14h07rovGanaXTMRYBgcjODDzwDfZJ7m0AoJtNmGH+JrN5O2ehM9uugIoKKJDH5kLZZZLo4fZS9Tb5HMIffKXJcKigs8Nt6ok4wsVvUCwejH02SaZPvEcxRboYtQr9/FhIZ0SK9f0ztWMsjcMG0cFzAXy02N0gb9QrcSlH/ZPslE96nGB0Va//sRKfrsH17IC3U4f0OYpBpE/5a6HroeNi+rk12Xz4+wB1qu7iqwap1jAAAAABJRU5ErkJggg==');
			background-repeat: no-repeat;
			background-position: 1px 5px;
		}*/
		
		&-title {
			font-family: $rb;
			font-size: 24px;
			line-height: 1.33;
			margin-bottom: 16px;
		}
		
		&-text {
			font: 16px/1.5 $rr;
			margin-bottom: 25px;
			//height: 150px;
			overflow: hidden;
			
			span {
				display: block;
				line-height: 1.5;
			}
			
		}

		&-tag {
			@include flex(row, flex-start, center);
			margin-top: 44px;

			font-family: $rr;
			font-size: 14px;
			line-height: 1.71;
			color: #adadad;

			&-img {
				width: 32px;
				height: 32px;
				margin-right: 5px;
			}
			&-text {
				width: calc(100% - 87px);
				&_view{
					&-counter{
						width: calc(100% - 51px);
					}
				}
			}
		}

		&-link {
			//font-family: $rm;
			//font-size: 18px;
			//line-height: 1.78;
			//color: $link-color;
			text-decoration: none;

			&-img {
				width: 50px;
				height: 50px;

				fill: #fafbfc;
				color: #012169;

				&:hover,
				&:active {
					fill: #012169;
					color: #fff;
				}
			}

			&:hover,
			&:active {
				//text-decoration: underline;
			}
		}
	}
}

@media screen and (max-width: $tab-max) {
	.preview {
		background: none!important;
		
		&:before {
			display: none;
		}
		
		&-container {
			padding: 0;
		}
		
		&-wrap {
			@include flex(column, space-between, initial);
		}
		
		&__main {
			
			&-wrap {
				padding: 120px 20px 120px;
				width: 100%;
				justify-content: flex-start;

				&_news {
					padding: 0;
				}
			}
			
			&-title {
				font-size: 24px;
				margin-bottom: 20px;
			}
			
			&-text {
				font-size: 22px;
				margin-bottom: 20px;
			}
			
		}
		
		&__events {
			width: 100%;
			&-count{
				right: 0;
			}
			.swc-slider-arrows {
				left: 0px;
			}
		}
		
		&__event {
			&-img {
				//display: none;
				background-color: rgba(0,48,135,.55);
			}

			&-title {
				margin-bottom: 16px;
			}
			&-text {
				margin-bottom: 35px;
			}


			&-body {
				padding: 18px 0px 80px;
				&_counter {
					padding: 30px 20px 30px 20px;
				}
			}
			&-tag {
				margin-top: 30px;
			}
		}
	}
}

@media screen and (max-width: $tab-min) {

	.preview {

		&__event {
			&-text {
				display: none;
			}
			&-body {
				padding-bottom: 70px;
			}
			&-tag {
				margin-top: 21px;
			}
		}

		&__events {

			&_news {
				//position: absolute;
				//height: 472px;
				//top: 0;
				//left: 0;
				//padding-top: 10px;
				//z-index: 1;
				//background-color: rgba(255, 255, 255, 0.6);
			}

		}
		&-wrap {
			&_news {
				position: relative;
			}
		}

		&__main {

			&-wrap {
				padding: 80px 20px 80px;

				&_news {
					padding: 0;
				}
			}
			&-img {
				height: 216px;
			}
		}

	}

}





































