.content {
	position: relative;
	margin-top: 140px;
	flex: 1 0 auto;
	z-index: 1;

	&_fullscreen-banner {
		margin-top: calc(100vh + 300px);
	}
	&__no-header {
		margin-top: 0;
	}

	//&.about-top-show-blocks {
	//	z-index: unset;
	//}
}

@media screen and (max-width: $tab-max) {
	.content {
		&_fullscreen-banner {
			margin-top: calc(100vh + 300px);
		}
	}
}

@media screen and (max-width: $tab-min) {
	.content {
		margin-top: 50px;

		&_fullscreen-banner {
			margin-top: calc(100vh + 300px);
		}
		&__no-header {
			margin-top: 0;
		}
	}
}