.advantages {
  padding-top: 60px;
  background-color: #f4f4f4;

  & .swc-subtitle{
    max-width: 790px;
  }
  &__title {
    font: 40px/1.4 $inter;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 800;
  }
  
  &__subtitle {
    font: 24px/1.33 $inter;
    margin-bottom: 56px;
    text-align: center;
  }

  &__items {
    font-size: 0;
    max-width: 1020px;
    margin: auto;
  }
  
  &__item {
    font-size: initial;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-right: 40px;
    margin-bottom: 60px;

    &:nth-child(even) {
      padding-right: 0;
    }

    &-img {
      width: 80px;
      height: 80px;
      margin-bottom: 32px;
      margin-left: 8px;
      text-align: center;
      position: relative;
      &:before {
        width: 0;
        height: 100%;
        content: '';
        display: inline-block;
        vertical-align: bottom;
        position: absolute;
        width: 96px;
        height: 96px;
        background: #fff;
        border-radius: 50%;
        z-index: 0;
        left: -8px;
        top: -8px;
      }

      svg {
        max-width: 100%;
        max-height: 100%;
        width: 80px;
        height: 80px;
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        z-index: 10;
      }
    }

    &-title {
      font: 24px/1.38 $inter;
      color: #000;
      margin-bottom: 16px;
      font-weight: 800;
    }

    &-list {
      &-item {
        font: 16px/2 $inter;
        margin-bottom: 16px;
        position: relative;
        padding-left: 20px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #000;
          top: 12px;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $tab-max) {
  .advantages {
    padding-bottom: 60px;

    &__items {
      max-width: 600px;
      margin: auto;
    }

    &__item {
      width: 100%;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $tab-min) {

  .advantages {
    &__item {
      margin-bottom: 40px;
    }
    & .swc-subtitle, & .swc-title{
      text-align: left;
      max-width: 600px;
    }
  }

}

@media screen and (max-width: 600px) {

  .advantages {
    & .swc-subtitle, & .swc-title{
      text-align: center;
    }
  }

}

































