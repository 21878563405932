html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box;
  font-family: $rr;
  font-weight: normal;
}

b,
strong {
  font-family: $rb;
}

body {
  line-height: 1.3;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  outline: none;
  color: $main-blue;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    text-decoration: underline;
  }
}

label {
  cursor: pointer;
  user-select: none;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f1f1f1;
  margin: 1em 0;
  padding: 0;
}

input, select, textarea {
  vertical-align: middle;
  outline: none;
  border: none;
  box-sizing: border-box;
  font-family: $rr;
  @include placeholder-color(#9b9b9b);
  font-size: 14px;
}

img, svg {
  vertical-align: bottom;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}