.etp {

  &-history {
    background-color: #f4f4f4;
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;

    &__img-wrap {
      //@include outg;
      max-height: 797px;
      overflow: hidden;
      margin: auto;
      position: relative;
    }

    & .swc-subtitle {
      margin-bottom: 64px;
      max-width: 1060px;
    }

    &__img {
      padding-top: 56.32%;
      background: url('../img/bg/park-history.jpg') no-repeat center;
      background-size: cover;
      position: relative;
      z-index: 200;
      &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000032;
      }
    }

    &__btn {
      top: 45%;
      width: 270px;
      height: 60px;
      bottom: 0;
      z-index: 500;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;

      .swc-btn {
        width: 270px;
        height: 60px;
        font-size: 18px;
        padding: 18px 25px 21px 25px;
        box-sizing: border-box;
      }
    }

    &__slider {
      width: 100%;
      height: 100%;
      max-height: 797px;
      top: 0;

      &-wrapper {
        position: relative;
        max-height: 797px;
        height: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 100px;

      }
    }

    &__slideshow {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 1440px;
      height: 100%;
      max-height: 797px;
      margin: 0 auto;
      overflow: hidden;

      .slider {
        position: relative;
        width: 100vw;
        height: 100vw;
        max-height: 797px;
        z-index: 1000;

        * {
          outline: none;
        }
      }

      .slick-slide {
        max-width: 1440px;
        width: 100%;
        max-height: 797px;
      }

      &.slideshow-right {
        left: 0;
        z-index: 1;
        width: 50%;
        pointer-events: none;
      }

      .slider {
        left: 0;
        //position: absolute;

        &.slideshow-left {
          max-width: 1440px;
          z-index: 1000;
        }
      }
    }

    &__item {
      height: 100vh;
      max-height: 797px;
      width: 100vw;
      position: relative;
      overflow: hidden;
      border: none;

      & img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #012169;
        opacity: 0.35;
      }
    }

    &__text {
      width: 50%;
      max-width: 620px;
      margin-left: 15px;

      &-wrapper {
        position: absolute;
        bottom: 79px;
        z-index: 200;
        width: 100%;
        max-width: 1240px;
        text-align: left;
        color: #fff;
        font: 20px/30px $rm;
        pointer-events: none;
        margin: 0 auto;
        left: 0;
        right: 0;
      }

      &-span {
        font: 16px/30px $rr;
        display: block;
        margin-top: 30px;
      }
    }
  }

  &__lines {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    display: flex;
    max-height: 44px;
    align-items: flex-end;
    max-width: 1240px;
    justify-content: flex-start;
    background-color: transparent;
  }

  &__line {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 28px;
    max-width: 40px;
    margin-right: 2%;
    font-size: 0;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      height: 44px;

      &::before {
        height: 44px;
        bottom: 0;
        transition: all .9s ease-in;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 28px;
      right: 50%;
      background-color: #ffffff;
    }

    &:hover::before {
      opacity: 0.5;
    }
  }

  &__arrow {
    width: 100px;
    height: 100px;
    font-size: 50px;
    color: #ffffff;
    position: absolute;
    top: 50%;
    z-index: 1000;
    background: url('../img/icons/arrow-etp.svg') no-repeat center;
    cursor: pointer;

    &-prev {
      left: 0;
      transform: rotate(180deg);
    }

    &-next {
      right: 0;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: $tab-max) {
  .etp {

    &-history {

      &__slider {
        max-width: 768px;
        max-height: 427px;

        &-wrapper {
          max-width: 768px;
          max-height: 427px;
        }
      }

      &__slideshow {
        max-width: 768px;
        max-height: 427px;

        .slider {
          max-width: 768px;
          max-height: 427px;

          * {
            outline: none;
          }
        }

        .slick-slide {
          max-width: 768px;
          max-height: 427px;
        }

        .slider {

          &.slideshow-left {
            max-width: 768px;
          }
        }
      }

      &__item {
        height: 100vh;
        max-height: 427px;
        width: 100vw;
        max-width: 768px;
      }

      &__text {
        width: 100%;
        max-width: 545px;
        margin-left: 0;

        &-wrapper {
          max-width: 678px;
          font: 18px/24px $rm;
          bottom: 49px;
        }

        &-span {
          font: 12px/18px $rr;
          display: block;
          margin-top: 2px;
        }
      }
    }

    &__lines {
      bottom: 17px;
      max-height: 31px;
      max-width: 678px;
    }

    &__line {
      max-height: 17px;
      max-width: 30px;

      &.active {
        height: 31px;

        &::before {
          height: 31px;
          bottom: 0;
        }
      }

      &::before {
        width: 1px;
        height: 17px;
      }
    }

    &__arrow {
      width: 45px;
      height: 45px;
      top: 45%;
      background-size: 50%;
    }
  }
}

@media screen and (max-width: $tab-min) {
  .etp {
    &-history {
      padding-top: 60px;

      &__slider {
        max-width: 375px;
        max-height: 208px;

        &-wrapper {
          max-width: 375px;
          max-height: 208px;
        }
      }

      &__slideshow {
        max-width: 375px;
        max-height: 208px;

        .slider {
          max-width: 375px;
          max-height: 208px;

          * {
            outline: none;
          }
        }

        .slick-slide {
          max-width: 375px;
          max-height: 208px;
        }

        .slider {

          &.slideshow-left {
            max-width: 375px;
          }
        }
      }

      &__item {
        height: 100vh;
        max-height: 208px;
        width: 100vw;
        max-width: 375px;
      }

      &__text {
        width: 100%;
        max-width: 283px;

        &-wrapper {
          max-width: 335px;
          font: 10px/14px $rm;
          bottom: 8px;
        }

        &-span {
          font: 8px/12px $rr;
          display: block;
        }
      }
    }

    &__lines {
      display: none;
    }

    &__arrow {
      width: 30px;
      height: 30px;
      top: 45%;
      background-size: 50%;

      &-prev {
        left: 10px;
      }

      &-next {
        right: 10px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .etp {
    &-history {
      padding-bottom: 20px;
      &__img-wrap{
        padding-bottom: 82px;
      }
      &__btn {
        top: auto;
        width: 100%;
        bottom: 0;
        margin: 0 auto;
        position: absolute;
        left: 0;
        right: 0;

        .swc-btn {
          width: calc(100% - 48px);
          height: 50px;
          padding: 13px 41px 13px 41px;
          margin: 0px 24px 0;
        }
      }
    }
    &__arrow{
      display: none;
    }
  }
}