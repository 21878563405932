.webinar-reg {
	padding-top: 130px;
	padding-bottom: 60px;
	background-color: #fafbfc;
	
	&-wrap {
		@include flex(row, space-between, initial);
	}
	
	&__desc {
		background-color: $main-blue;
		color: white;
		padding: 50px 40px 60px 70px;
		width: 60%;
		
		&-title {
			font-size: 36px;
			font-family: $rb;
			margin-bottom: 20px;
		}
		
		&-text {
			font-size: 20px;
			margin-bottom: 30px;
		}
		
		&-members {
			padding-bottom: 20px;
		}
		
		&-member {
			@include flex;
			width: 100%;
			
			&-img {
				padding-right: 40px;

				img {
					border-radius: 50%;
				}
			}
			
			&-name {
				font-size: 18px;
				font-family: $rb;
				line-height: 1.44;
			}
			
			&-text {
				font-size: 14px;
				line-height: 1.86;
			}
		}
	}
	
	&__form-wrap {
		width: 40%;
		padding: 0 0 0 20px;
	}
	
	&__form {
		height: 100%;
		padding: 30px 14% 40px;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
		
		&-title {
			font-size: 24px;
			color: $main-blue;
			font-family: $rb;
			margin-bottom: 20px;
		}
		
		.swc-btn {
			width: 100%;
		}
	}
}

@media screen and (max-width: $tab-max) {
	.webinar-reg {
		&-wrap {
			@include flex(column, space-between, initial);
		}

		&__desc {
			width: 100%;

			&-title {
				font-size: 24px;
			}

			&-text {
				font-size: 16px;
			}

		}
		
		&__form {
			max-width: 636px;
			margin: auto;
			padding: 30px 8% 40px;
			
			&-wrap {
				padding: 50px 0 0;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $tab-min) {
	.webinar-reg {
		padding-top: 60px;

		&-wrap {
			margin: 0 -10px;
		}
		
		&__desc {
			text-align: center;
			padding: 50px 10px 40px;
//			margin: 0 -10px;
			
			&-members {
				padding-bottom: 50px;
			}
			
			&-member {
				flex-direction: column;
				
				&-img {
					padding: 0;
					margin-bottom: 15px;
				}
				
				&-name {
					font-size: 14px;
					margin-bottom: 5px;
				}
			}
		}
		
		&__form {
			padding: 0 10px;
			background-color: transparent;
			box-shadow: none;
			
			&-wrap {
				padding-top: 50px;
			}
			
			&-title {
				text-align: center;
				margin-bottom: 50px;
			}
		}
	}
}



































