.swc {

	&-title {
		font: 39px/1.5 $inter;
		margin: 0 auto 20px;
		text-align: center;
		width: 100%;
		max-width: 1060px;
		font-weight: 800;
		letter-spacing: -2px;
		&_single {
			margin-bottom: 80px;
		}

		&_white {
			color: white;
		}
	}

	&-subtitle {
		font: 24px/1.5 $inter;
		margin: 0 auto 56px;
		text-align: center;
		width: 100%;
	}

	&-btn {
		display: inline-block;
		background-color: $btn;
		color: #000;
		padding: 14px 55px;
		font: 16px/20px $inter;
		border-radius: 2px;
		transition: all .2s ease;
		text-align: center;
		min-width: 180px;
		box-sizing: border-box;
		border: 1px solid $btn;
		cursor: pointer;
		font-weight: 900;

		&:hover {
			background-color: $btn-hover;
			border-color: $btn-hover;
			text-decoration: none;
		}

		&:active {
			background-color: $btn-active;
			border-color: $btn-active;
		}

		&:focus {
			background-color: $btn-hover;
		}

		&:disabled {
			background-color: #144fc8;
			color: #9aaccf;
			opacity: 0.9;

			&:hover {
				background-color: #144fc8;
				cursor: not-allowed;
			}
		}

		&_big {
			padding: 18px 55px;
			font: 18px/22px $rr;
		}

		&_trans {
			background-color: transparent;
			border-color: white;

			&:hover, &:active {
				background-color: white;
				color: $main-blue;
			}
		}
	}

	&-form {

		&__title {
			font: 32px/1.28 $rb;
			margin-bottom: 25px;
			text-align: center;

			&_single {
				margin-bottom: 45px;
			}
		}

		&__subtitle {
			font: 24px $rr;
			margin-bottom: 40px;
			text-align: center;
		}

		&-body {
			margin-bottom: 20px;

			& + .swc-btn {
				width: 100%;
			}
		}

		&-group {
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			 .form-group.has-error {
				.swc-form-input {
					border: 1px solid #ff3f3f;
				}
			}
		}

		&-label {
			//user-select: none;
			display: block;
			font-size: 14px;
			margin-bottom: 5px;

			span {
				display: block;
				font-size: 14px;
				margin-bottom: 5px;
			}
		}

		&-input {
			width: 100%;
			height: 50px;
			border: 1px solid #9b9b9b;
			text-indent: 16px;
			border-radius: 2px;
			@include placeholder-color($main-blue);
			font-size: 16px;

			&:focus {
				border: 1px solid #2164f4;
			}

			&-error {
				font: 14px $rr;
				color: #c00;
				margin-top: 10px;
				color: #ff3f3f;
			}
		}

		&_dark {
			.swc-form-group .has-error {
				margin-bottom: -10px;
				.swc-form-input-error {
					margin-top: 4px;
					padding: 0;
					color: #ff3f3f;
					font: 14px/1.29 $rr;
				}
				.swc-form-input{
					border-color: #ff3f3f;
				}
			}
		}

		&__radio-buttons.not-active {
			display: none;
		}

	}

	&-radio {
		position: absolute;
		opacity: 0;
		z-index: 50;

		&__wrap {
			position: relative;
			overflow: hidden;
		}

		&__text {
			position: relative;
			padding-left: 26px;
			font: 14px/21px $rr;

			&:before {
				display: block;
				content: '';
				position: absolute;
				width: 18px;
				height: 18px;
				background-color: #ffffff;
				border-radius: 50%;
				border: 1px solid #e1e1e1;
				box-sizing: border-box;
				left: 0;
				top: 2px;

			}

			& a {
				text-decoration: underline;

				&:focus {
					border: 1px solid $btn;
				}
			}
		}

		&:checked + .swc-radio__text:after {
			display: block;
			content: '';
			position: absolute;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background-color: #ffffff;
			border: 5px solid $btn-active;
			top: 3px;
			left: 1px;
			box-sizing: border-box;
		}

		&:focus + .swc-radio__text:before {
			border: 1px solid $btn;
		}

		&:checked + .swc-radio__text:before {
			opacity: 0;
		}

		&__open {
			font: 14px/18px $rr;
			color: #000;
			margin-top: 20px;
			position: relative;
			cursor: pointer;

			&:after {
				content: '';
				background: url("../img/gdpr_arrow.svg") no-repeat;
				width: 12px;
				height: 13px;
				position: absolute;
				top: 5px;
				right: 0;
			}

			&.active {
				padding-bottom: 20px;

				&:after {
					transform: rotate(180deg);
					top: 0;
				}
			}

			&_dark {
				color: #fff;

				&:after {
					background: url("../img/gdpr_arrow_white.svg") no-repeat;
				}
			}
		}
	}


		&-play-btn {
			width: 61px;
			height: 61px;
			transition: all .2s ease;
			cursor: pointer;
			user-select: none;
		}

		&-slider {

			&-arrows {
				@include flex;
				flex-wrap: nowrap;
			}

			&-arrow {
				width: 50px;
				height: 50px;
				position: relative;
				cursor: pointer;
				transition: all .2s ease;
				//background-color: white;

				&:first-child {
					margin-right: 5px;
				}

				&:before {
					display: block;
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					width: 10px;
					height: 10px;
					border-bottom: 3px solid #000;
					border-left: 3px solid #000;
				}

				&:after {
					display: block;
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					width: 20px;
					height: 3px;
					background-color: #000;
				}

				&_white {
					background-color: transparent;

					&:before {
						border-bottom: 3px solid white;
						border-left: 3px solid white;
					}

					&:after {
						background-color: white;
					}
				}

				&_prev {
					&:before {
						left: 16px;
						transform: rotate(45deg);
					}
				}

				&_next {
					&:before {
						right: 16px;
						transform: rotate(-135deg);
					}
				}

				&:hover {
					background: #000;

					&:before {
						border-bottom-color: white;
						border-left-color: white;
					}

					&:after {
						background-color: white;
					}
				}
			}

		}

		&-tab {
			padding-top: 50px;
		}

		&-tab-names {
			@include flex(row, space-between, center);
			flex-wrap: wrap;
			max-width: 1000px;
			margin: 0 auto 64px;

			&_news {
				max-width: 100%;
			}
		}

		&-tab-name {
			font: 20px/1.35 $inter;
			color: #000;
			text-transform: uppercase;
			cursor: pointer;
			user-select: none;
			transition: all .2s ease;
			padding: 0 15px;
			font-weight: 900;
			&:hover, &.active {
				color: #000;
				text-decoration: underline;
			}

			&_news,
			&_news_h1 {
				font: 14px/1.43 $rm;
				text-align: center;
				color: #003087;
			}

			&_news {
				text-decoration: none;
				flex: auto;
				margin: 0 20px;
				border-bottom: 2px solid #003087;
				padding-bottom: 16px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover, &.active {
					color: #005bff;
					text-decoration: none;
					border-bottom: 2px solid #005bff;
				}
			}
		}

		&-pin {
			position: absolute;
			top: 0;
			left: 0;
			width: 30px;
			height: 30px;
			transform: translate(-50%, -50%);

			&:before {
				position: absolute;
				content: '';
				display: block;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				border: 8px solid white;
				background-color: #b0ee3f;
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
				cursor: pointer;
				transition: all .2s ease;
				box-sizing: border-box;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}

			//&.active {
			//	&:before {
			//		border: 10px solid #003087;
			//		background-color: #fff;
			//	}
			//}
		}

		&-thumb {

			&_video {
				position: relative;

				&:before {
					position: absolute;
					display: block;
					content: '';
					width: 100%;
					height: 100%;
					border: 3px solid #2164f4;
					top: 0;
					left: 0;
					box-sizing: border-box;
				}

				&:after {
					position: absolute;
					display: block;
					content: '';
					width: 61px;
					height: 61px;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MSIgaGVpZ2h0PSI2MSIgdmlld0JveD0iMCAwIDYxIDYxIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiMyMTY0RjQiIGQ9Ik0zMC41Ljc0NGMxNi40MzQgMCAyOS43NTYgMTMuMzIyIDI5Ljc1NiAyOS43NTZTNDYuOTM0IDYwLjI1NiAzMC41IDYwLjI1Ni43NDQgNDYuOTM0Ljc0NCAzMC41IDE0LjA2Ni43NDQgMzAuNS43NDR6Ii8+ICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNMzkuNjMyIDI5LjI0OGwtMTEuNjc1LTcuNDg5Yy0uMzQ1LS4yMDktLjc3My0uMTc0LTEuMDk3LS4xNzQtMS4yOTUgMC0xLjI4OSAxLjAxNy0xLjI4OSAxLjI3NXYxNS4zMDZjMCAuMjE4LS4wMDYgMS4yNzYgMS4yODkgMS4yNzYuMzI0IDAgLjc1Mi4wMzQgMS4wOTctLjE3NGwxMS42NzUtNy40OWMuOTU4LS41OC43OTItMS4yNjQuNzkyLTEuMjY0cy4xNjYtLjY4Ni0uNzkyLTEuMjY2eiIvPiAgICA8L2c+PC9zdmc+);
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
			}

		}

		&-bg {
			background-color: #fafbfc;
		}

		&-link {

		}

}



@media screen and (max-width: $tab-min) {

	.swc {

		&-tab {

			&-name {
				padding: 0;
				margin-bottom: 32px;

				&_news {
					display: block;
					width: 100%;
					margin: 0 0 20px;
					padding-bottom: 16px;
				}
			}
		}



		&-title {
			font-size: 40px;
			line-height: 1.5;

			&_single {
				margin-bottom: 60px;
			}
		}

		&-subtitle {
			font-size: 24px;
			line-height: 1.5;
		}

		&-form {

			&__title {
				font: 28px/1.28 $rb;
				margin-bottom: 45px;
				text-align: center;
			}

		}

		&-radio {

			&__wrap:first-child {
				margin-top: 0;
			}
		}

		&-thumb_video {

			& img {
				width: 100%;
			}
		}

	}


}

@media screen and (max-width: 600px) {

	.swc {
		&-pin {
			width: 16px;
			height: 16px;

			&:before {
				width: 16px;
				height: 16px;
				border: 3px solid white;
			}
		}
		&-tab {
			&-names {
				@include flex(column, flex-start, center);
				margin-bottom: 32px;
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.swc{
		&-title {
			font-size: 32px;
		}

		&-subtitle {
			font-size: 18px;
			line-height: 1.78;
		}
	}
}

































